import React, { useEffect,useState } from 'react'
import { Button,Grid } from '@mui/material';

import { styled } from '@mui/system';
import { useCreateGalleryPhotosMutation } from '../Services/modules/school'
import SubmitButton from './InputFields/SubmitButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Input = styled('input')({
    display: 'none',
  });
  
  const UploadButton = styled(Button)({
    marginTop: '16px',
  });
  const PreviewContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '8px',
    flexWrap: 'wrap',
  });
  
  const PreviewImage = styled('img')({
    width: '100px',
    height: '100px',
    marginRight: '8px',
    marginBottom: '8px',
  });
function GalleryPhotosForm({gallery_id,programId}) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);
    const [createGalleryPhotos, { data, isSuccess, isLoading, isError, error }] = useCreateGalleryPhotosMutation();
   
    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles(files);
      
        const previews = Array.from(files).map((file) =>
          URL.createObjectURL(file)
        );
        setFilePreviews(previews);
        };
    const handleUpload = () => {
        if (selectedFiles.length === 0) {
            console.log('No files selected');
            toast.error('No files selected')
            return; // Exit the function if no files are selected
          }
        const form = new FormData();                 
        form.append("documentId",gallery_id)
        form.append("programId",programId)          
        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            if (file.size <= 25 * 1024 * 1024) {
              form.append('photos', file);
            } else {
             return console.log(`Skipping file ${file.name} due to size limit exceeded`);
            }
          }
        createGalleryPhotos(form)
      }
      const clearForm=()=>{
     
        setSelectedFiles([])
        setFilePreviews([])
       }
       useEffect(() => {   
     
        if (isSuccess) {         
          console.log("data",data)   
          if(data.error) 
          {
            toast.error(data.message) 
            clearForm()
          }
          if(!data.error)
          {
            toast.success(data.message)  
            clearForm()      
          }
                    
        }
        else {
        console.log("In error")
           
        }
    }, [isLoading]);
  return (
   <>
    <ToastContainer />
    <label htmlFor="upload">
        <Button variant="contained" component="span">
          Choose File
        </Button>
      </label>
      <Input
        type="file"
        id="upload"
        multiple
        onChange={handleFileChange}
      />
      {filePreviews.length > 0 && (
         <div style={{ display: "flex", flexWrap: "wrap" }}>
        <PreviewContainer>
          {filePreviews.map((preview, index) => (
            <PreviewImage
              key={index}
              src={preview}
              alt={`Preview ${index}`}
            />
           
          ))}
        </PreviewContainer>
        </div>
      )}
      <Grid 
            container 
            spacing={3}                 
            >
                 <Grid item xs={12} md={2} lg={3}> 
                 <SubmitButton 
              caption="Upload" 
             isLoading={isLoading}
            // disabled={!isFormValid}
              button_color={'green'}
              onClick={()=>handleUpload()}
              />
                 </Grid>
                 <Grid item xs={12} md={2} lg={3}>
                 <SubmitButton 
              caption={"Reset"} 
              button_color={'red'}
             
              onClick={()=>clearForm()}
              /> 
                 </Grid>
            </Grid>     
   </>
  )
}

export default GalleryPhotosForm