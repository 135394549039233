import React,{useEffect,useState} from 'react'
import { useLoginMutation } from '../Services/modules/auth';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../Store/UserData';
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import Base from '../Components/Base';
import AdminTemplateNew from '../Components/Template/AdminTemplate';
import TextBox from '../Components/InputFields/TextBox';
import SubmitButton from '../Components/InputFields/SubmitButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid } from '@mui/material';

function Login() {
    const isLoggedin = useSelector(state => state.userData.user_data == null ? false : true);
   console.log("isLoggedin",isLoggedin)
    const dispatch = useDispatch();
   
    const [state, setState] = React.useState({
      email: "",
      password:""
    })
    const [isFormValid, setIsFormValid] = useState(false);

   
    const [Login, { data, isSuccess, isLoading, isError, error }] = useLoginMutation();
    const theme = createTheme();
    useEffect(() => {    
        if (isSuccess) {
            console.log("InSuccess",data);
          
            if(data.error==true)
            {
              toast.error(data.message)
              return 
            }
            dispatch(updateUserData({ data: data }))           
        }
        if (isError) {
            alert("In error")
            console.log(error);
        }
    }, [isLoading]);

    useEffect(() => {
      validateForm();
    }, [state]);
    const handleKeypress = e => {
    
      if (e.keyCode === 13) {
        onSubmit();
      }
    };

    var payload = {
        email: state.email,
        password: state.password,
       
    };
     function onSubmit (){
    
      Login(payload)
      
  
   }
   const validateForm = () => {
    const { email, password } = state;
    if (email !== '' && password !== '') {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };   
   
    function handleChange(e) {
      const value = e.target.value;

      setState({
        ...state,
        [e.target.name]: value
      });
      validateForm()
    }

   const loginScreen=()=>{
   
    return (
      isLoggedin?<Navigate to="/dashboard"/>: 
      <>  
    
      <ThemeProvider theme={theme}>
      <ToastContainer />
          <CssBaseline />
          <Box
            sx={{
             
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}/>           
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box sx={{ mt: 1,textAlign: 'center' }}>
         
           
              <TextBox 
              id={"email"} 
              label={"Email"} 
              name={"email"} 
              autoFocus={true}
              value={state.email}
              onChange={handleChange}
             
              />
        
        
              <TextBox 
              id={"password"} 
              inputType={"password"} 
              label={"Password"} 
              name={"password"} 
              autoFocus={false}
              value={state.password}
              onChange={handleChange}
              onKeyPress={handleKeypress}
              sx={{ width: "100%" }}
              />    
                   
                 
              <SubmitButton 
              caption={"Sign In"}              
              onClick={()=>onSubmit()}
              isLoading={isLoading}
             disabled={!isFormValid}
              />
                  

            </Box>
          </Box>
       
        
        

      </ThemeProvider>
    </>
    )
  
   }
 
  return(
   
    <AdminTemplateNew>
{loginScreen()}
</AdminTemplateNew>
   
  )
}

export default Login