import { Grid,DialogTitle,Typography, Box, Button, IconButton, TextField } from '@mui/material'
import React,{ useState,useEffect, useRef } from 'react'
import SubmitButton from './InputFields/SubmitButton'
import TextBox from './InputFields/TextBox'
import { useSelector,useDispatch } from 'react-redux'
import { useCreateGalleryYearMutation, useSchoolBySectionMutation } from '../Services/modules/school'
import { useCreateStaffMutation,useUpdateStaffMutation } from '../Services/modules/staff'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import updateStaff from '../Services/modules/staff/updateStaff'
import { clearStaffData } from '../Store/StaffData'

function GalleryYearForm({is_retire,staff_type}) {
    const years = [
        { key: "", value: "--select--" },
        { key: "2023", value: "2023" },  
        // { key: "2024", value: "2024" },          
      ];    
      const[isupdateRecord,setUpdateRecord]=useState(false);     
      const[year,setYear]=useState('')  
    const [isFormValid, setIsFormValid] = useState(false);

    
    var school_id=""
var global_school_id=useSelector(state => state.userData.user_data.school_id)
if(global_school_id!==undefined)
{
school_id=global_school_id
}
const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);
const userRole=useSelector(state => state.userData.user_data.role)

   
    // useEffect(()=>{  
    //   clearStaffData()
    //  },[])
   

    const [CreateGalleryYear, { data, isSuccess, isLoading, isError, error }] = useCreateGalleryYearMutation();   
    // const [UpdateStaff, { 
    //   data:updateStaffData,
    //    isSuccess:updateStaffIsSuccess, 
    //    isLoading:updateStaffIsLoading=false, 
    //    isError:updateStaffIsError, 
    //    error:updateStaffError }] = useUpdateStaffMutation();   
    
   
     useEffect(() => {
      validateForm();
    }, [year]);
    useEffect(() => {   
     
      if (isSuccess) {         
        console.log("data",data)   
        if(data.error) 
        {
          toast.error(data.message) 
        }
        if(!data.error)
        {
          toast.success(data.message)        
        }
                  
      }
      else {
      console.log("In error")
         
      }
  }, [isLoading]);
    const validateDate = (date) => {
      // Regular expression pattern to match the date format DD/MM/YYYY
      const dateFormatRegex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
      return dateFormatRegex.test(date);
    // return setIsDateValid(dateFormatRegex.test(date));
    };
    const validateForm = () => {
     
    
          if (year !== '') {
            setIsFormValid(true);
          } 
          else 
          {           
            setIsFormValid(false);
          }       
    };   
    const clearForm=()=>{
     
     setYear('')
    }
   
  
    const GalleryYearFormScreen=()=>{

        function onSubmit (){  
     let payload={
      year:year,
      role:userRole,
      school_id:school_id
     }         
                 
           CreateGalleryYear(payload)    
           clearForm()           
         }
         
 
        return (
         
    <>
     <ToastContainer />

    <Grid container spacing={2} >
  
            <Typography component="h1" variant="h6">
           Add Year
                    </Typography>
            
  <Grid container item xs={12} direction="column" >
  
             <TextBox
               values={years}
               label={"year*"}
               id={"year"}
               name={"year"}
              isSelect={true}
              value={year}  
              onChange={(e)=>{setYear(e.target.value)}}
              />
 
         
               <Grid 
            container 
            spacing={3}                 
            >
               <Grid item xs={12} md={2} lg={3}>  
              <SubmitButton 
              caption={isupdateRecord? "update": "Save"} 
             isLoading={isLoading}
             disabled={!isFormValid}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
              </Grid>
              <Grid item xs={12} md={2} lg={3}>  

               <SubmitButton 
              caption={"Reset"} 
              button_color={'red'}
             
              onClick={()=>clearForm()}
              />
              </Grid>
              </Grid>
             
           
  </Grid>
  
</Grid>

</>
         
          )
    }

    return(
        GalleryYearFormScreen()
    )
 
}

export default GalleryYearForm