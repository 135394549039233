import { Grid,Paper } from '@mui/material'
import React,{useEffect,useState,useRef} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { updateSchoolData } from '../../Store/SchoolData';

import { useGetSchoolByIdQuery} from '../../Services/modules/school';

import SchoolProfileForm from '../../Components/SchoolProfileForm'
import SchoolProfileInfo from '../../Components/SchoolProfileInfo'
import AdminTemplate from '../../Components/Template/AdminTemplate'

function DashboardSchool() {
    const dispatch = useDispatch();
    const school_id=useSelector(state => state.userData.user_data.school_id)
    const timestampRef = useRef(Date.now()).current;
    const[isSchoolId,setIsSchoolId]=useState(false);
    const { 
        data,  
        isLoading,
        isSuccess,
        isError,
        refetch
       } = useGetSchoolByIdQuery({school_id:school_id});

       useEffect(() => {
        console.log("In use effect")
        const fetchData = async () => {
          await refetch();
        };    
        fetchData();
      }, []);
      
       useEffect(() => {
      
        if (isSuccess) {
            console.log("InSuccess",data.data[0]);
            dispatch(updateSchoolData(data.data[0]))
            setIsSchoolId(true)
           // GetAllSchools()
            if(data.error==true)
            {
             // toast.error(data.message)
              return 
            }
            else
            {
             // toast.success("School created successfully")
            }
            //dispatch(updateUserData({ data: data }))
           
        }
        if (isError) {
            alert("In error")
            //console.log(error);
        }
    }, [data]);
   
    const dashboardScreen=()=>{
        return(
           <>
           <Grid 
            container 
            spacing={3} 
            mb={5}                
            >
                <Grid item xs={12} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 70,
                    
                    
                  }}
                >
                एकूण शिक्षक
                
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 70,
                  }}
                >
                  माजी विद्यार्थी
                
                
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 70,
                  }}
                >
                  एकूण विद्यार्थी
                 
                
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 70,
                  }}
                >
                  माजी शिक्षक
                 
                
                </Paper>
              </Grid>
            </Grid>
            <Grid 
            container 
            spacing={3} 
            mb={5}                
            >
                 <Grid item xs={12} md={4} lg={5}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: "auto",
                  }}
                >
                    {isSchoolId?<SchoolProfileForm/>:""}
                </Paper>
                 </Grid>

                 <Grid item xs={12} md={7} lg={7}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: "auto",
                  }}
                >
                    {isSchoolId? <SchoolProfileInfo/>:""}
                </Paper>
                 </Grid>
            </Grid>
            </>
        )
    }
    return (
  
        <AdminTemplate>
      {dashboardScreen()}
      </AdminTemplate>
     
    )
}

export default DashboardSchool