import { Grid,Button  } from '@mui/material'
import React from 'react'
import StaffInfo from '../Components/StaffInfo'
import AdminTemplate from '../Components/Template/AdminTemplate'
import{useNavigate} from 'react-router-dom'
function ReportActiveTeachingStaff() {
  let navigate=useNavigate()
    const reportActiveTeachingStaffScreen=()=>{
      const handleGoBack = () => {
      navigate('/reports')
      };
        return(
            <div style={{marginTop:"50px"}}>
               <Grid 
               container 
               spacing={3} 
               mb={5}  
                             
               >
                 <Button variant="contained" color="primary" onClick={handleGoBack}>
          Go Back
        </Button>
               </Grid>
       
            <Grid 
               container 
               spacing={3} 
               mb={5}  
                             
               >
<StaffInfo is_report={true} is_retire={false} staff_type={"teaching"}/>
</Grid>
</div>
        )
    }
  return (
    <AdminTemplate>
        {reportActiveTeachingStaffScreen()}
    </AdminTemplate>
  )
}

export default ReportActiveTeachingStaff