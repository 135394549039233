import React,{ useState,useEffect, useRef } from 'react'
import { Grid,DialogTitle,Typography, Box, Button, IconButton, TextField } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import { useSelector,useDispatch } from 'react-redux'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import DataTable from '../Components/DataTable'
import { useCreateDistrictMutation, useCreateTalukaMutation, useDeleteDistrictMutation, useDeleteTalukaMutation, useGetDistrictQuery, useGetTalukaQuery, useUpdateDistrictMutation, useUpdateTalukaMutation,useGetTalukaByDistrictMutation, useGetExamSchoolsQuery } from '../Services/modules/exam'
import { api } from '../Services/api'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Select, Input, Table, Icon,Space } from "antd"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


function ExamSchoolName() {
  const[isUpdate,setIsUpdate]=useState(false)
  const[districtList,setDistrictList]=useState([])
  const[selectedDistrict,setSelectedDistrict]=useState()
  const[talukaList,setTalukaList]=useState([])
  const[schoolList,setSchoolList]=useState([])
  const[selectedTaluka,setSelectedTaluka]=useState()
  const[schoolName,setSchoolName]=useState()
  const { 
    data: allDistricts= [],  
    isLoading:loading=true,
    isSuccess:success,
    isError:errorDistrict=false
   } = useGetDistrictQuery();
   const { 
    data: allSchools= [],  
    isLoading:loadingSchools=true,
    isSuccess:successSchools,
    isError:errorSchools=false
   } = useGetExamSchoolsQuery();
   const[GetTalukaByDistrict, {    
    data: talukaData= [],  
    isLoading:loadingtaluka=false,
    isSuccess:successtalukaFetch=false,
    
   } ]= useGetTalukaByDistrictMutation();
  
  var columns=[
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">            
           
           {/* <a onClick={()=>editSchoolName(record)} style={{color: "blue"}}><EditIcon style={{fontSize:'medium'}}/> </a>
          
            <a onClick={()=>openDeleteSchoolNameDialog(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>           */}
          </Space>
        ),
      },
      { 
        key: "school_name", 
        title: "School name", 
        dataIndex: "school_name", 
        },
    { 
        key: "taluka_name", 
        title: "Taluka name", 
        dataIndex: "taluka_name", 
        }
   ]
   useEffect(() => {   
    var districtlist=[{
      key:"",
      value:""
    }]     
   
      if (success) {         
        console.log(allDistricts.allDistricts)      
        allDistricts.allDistricts.forEach(element => {
        districtlist.push({
          key:element._id,
          value:element.district_name
        })
       });
       setDistrictList(districtlist)
                                   
      }
      else {
      console.log("In error")
         
      }
  }, [loading]);
  useEffect(() => {   
    var talukalist=[{
      key:"",
      value:""
    }]     
   
      if (successtalukaFetch) {         
        console.log(talukaData)      
        talukaData.allTalukas.forEach(element => {
          talukalist.push({
            key:element._id,
            value:element.taluka_name
          })
         });
         setTalukaList(talukalist)
      }
      else {
      console.log("In error")
         
      }
  }, [talukaData]);
  useEffect(() => {   
      
    var schoollist=[]  
      if (success) {         
        console.log(allSchools)      
        if(allSchools.allSchools.length>0)
        {
         
          allSchools.allSchools.forEach(element => {
            schoollist.push({
              _id:element._id,
              school_name:element.school_name,
              taluka_name:element.taluka_name
          })
         });
         setSchoolList(schoollist)
        }
       
                                   
      }
      else {
      console.log("In error")
         
      }
  }, [allSchools]);
  const handleKeypress = e => {
    
    if (e.keyCode === 13) {
    //  onSubmit();
    }
  };
  const onSubmit=()=>{
  
  }
  const districtOnChange=(e)=>{   
  var payload = {
    district_id: e.target.value,         
};
GetTalukaByDistrict(payload)
  
   }
  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);

  const ExamSchoolNameScreen=()=>{
   
    return(
      <div>
    
     <ToastContainer />
<Grid container spacing={2} >

{ 
isSuperAdmin?
<>      
<Grid container item xs={6} direction="column" >

<Typography component="h1" variant="h6">
School ऍड करा
        </Typography>
<TextBox
          // values={school_section_list}
           label={"जिल्हा "}
           id={"district"}
           name={"district"}
           autoFocus={true}             
           //onChange={(e)=>{setSelectedDistrict(e.target.value)}}   
           onChange={districtOnChange}
           values={districtList}
           value={selectedDistrict}  
          isSelect={true}
          onKeyPress={handleKeypress}
             
          />
          <TextBox
           values={talukaList}
           label={"तालुका"}
           id={"taluka"}
           name={"taluka"}
           autoFocus={false}             
           onChange={(e)=>{selectedTaluka(e.target.value)}}   
           value={selectedTaluka}  
          isSelect={true}
           
          />
           <TextBox
          
           label={"School Name"}
           id={"schoolName"}
           name={"schoolName"}
           autoFocus={true}             
           onChange={(e)=>{setSchoolName(e.target.value)}}   
           value={schoolName}  
          isSelect={false}
          onKeyPress={handleKeypress}             
          />
         {/* <div style={{color:"red"}}>{validateInput.school_section}</div> */}
        
          
           <Grid 
        container 
        spacing={3}                 
        >
           <Grid item xs={12} md={2} lg={3}>  
          <SubmitButton 
           caption={isUpdate? "update": "Save"} 
        
        // isLoading={isLoading}
          button_color={'green'}
        //  isLoading={!isUpdate? isLoading: isUpdateLoading}
          onClick={()=>onSubmit()}
          />
          </Grid>
          <Grid item xs={12} md={2} lg={3}>  

          </Grid>
          </Grid>
         
       
</Grid>
<Grid container item xs={6} direction="column" >

<DataTable columns={columns}
   Data={!loadingSchools? schoolList:[]}/>      
</Grid>
</>
          :""              
          }
</Grid>
  </div>
    )
  }
  return (
  <AdminTemplate>{ExamSchoolNameScreen()}</AdminTemplate>
  )
}

export default ExamSchoolName