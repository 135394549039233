import { Box, Container, Grid } from '@mui/material'
import React,{useEffect,useState,useRef} from 'react'

import { useSelector,useDispatch } from 'react-redux'
import { useGetStaffQuery,useDeleteStaffMutation } from '../Services/modules/staff';
import DataTable from './DataTable';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { updateStaffData,clearStaffData } from '../Store/StaffData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextBox from './InputFields/TextBox';
import { useSchoolBySectionMutation } from '../Services/modules/school';
import { useGetAgentsQuery, useGetAllExamFormsQuery } from '../Services/modules/exam';
import { Config } from '../Config';

function ExamFormInfo() {
    const dispatch=useDispatch()
  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);
  const[agents,setAgents]=useState([])

const user_role = useSelector(state => state.userData.user_data.role);

var school_id=0


const { 
    data: allExamForms= [],  
    isLoading:loading=true,
    isSuccess,
    isError
   } = useGetAllExamFormsQuery();

   const { 
    data: allAgents= [],  
    isLoading:loadingAgents=true,
    isSuccess:success,
    isError:errorAgents=false,
    error
   } = useGetAgentsQuery();
   
   useEffect(() => {   
    console.log("In useeffcet")
    var agentList=[{
      key:"",
      value:""
    }]     
   
      if (success) {   
        console.log("agentList",allAgents)      
       console.log(allAgents.data)      
        allAgents.data.forEach(element => {
            if(element.is_user_registered)
            agentList.push({
          key:element._id,
          value:element.full_name
        })
       });
       setAgents(agentList)
                                   
      }
      else {
      console.log("In error",error)
         
      }
  }, [loadingAgents]);
  
   useEffect(()=>{
    if(isSuccess)
    {
      
        console.log("allExamForms",allExamForms)
    }
   },[allExamForms])
  
   var columns=[
   
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">            
            {/* <a onClick={()=>showviewDetails(record)} style={{color: "blue"}}>
                <RemoveRedEyeIcon style={{fontSize:'medium'}}/> 
                </a>  */}
          
            {/* <a onClick={()=>deleteStaffConfirmAlert(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>           */}
          </Space>
        ),
      },
     
    { 
        key: "student_name", 
        title: "Student Name", 
        render: (_, record) => `${record.first_name} ${record.middle_name} ${record.last_name}`,
       // dataIndex: "first_name", 
        },
       
        { 
            key: "student_address", 
            title: "Address", 
            dataIndex: "student_address", 
            }, 
            { 
                key: "student_district", 
                title: "District", 
                dataIndex: "student_district", 
                },  
                { 
                    key: "student_taluka", 
                    title: "Taluka", 
                    dataIndex: "student_taluka", 
                    },   
            { 
                key: "exam_name", 
                title: "Exam", 
                dataIndex: "exam_name", 
                }, 
                { 
                    key: "exam_standard", 
                    title: "Standard", 
                    dataIndex: "exam_standard", 
                    }, 
                    { 
                        key: "school_name", 
                        title: "School Name", 
                        dataIndex: "school_name", 
                        }, 
               {
                    key: "school_district", 
                title: "School District", 
                dataIndex: "school_district",  
                },
                {
                    key: "school_taluka", 
                title: "School Taluka", 
                dataIndex: "school_taluka",  
                },
                {
                  key: "full_name", 
              title: "Agent Name", 
              dataIndex: "full_name",  
              },
              {
                key: "is_exported", 
            title: "Exported", 
            dataIndex: "is_exported",  
            },
                
                
   ]
   const [agent_list,setSchoolSections] = useState([
    { key: "pp", value: "पूर्व प्राथमिक" },
    { key: "p", value: "प्राथमिक" },
    { key: "s", value: "माध्यमिक" },
    { key: "hs", value: "उच्च माध्यमिक" }
   
  ]);
const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)
const[staffIdForDelete,setStaffIdForDelete]=useState("")
const[viewDetailsDialog,setViewDetailsDialog]=useState(false);
  const[viewTransferDialog,setviewTransferDialog]=useState(false)
   const editStaff=(record)=>{
    console.log(record,"StaffRecord")
dispatch(updateStaffData(record))
   }
   const deleteStaffConfirmAlert=(record)=>{
    //dispatch(clearStaffData())
    setDeleteDialogOpen(true)
    setStaffIdForDelete(record._id)

   }
   const deleteForm=()=>{
   // DeleteStaff({staff_id:staffIdForDelete})
    setDeleteDialogOpen(false)

   }
   const closeConfirmAlert=()=>{
    
    setDeleteDialogOpen(false)

   }
   const showviewDetails=(record)=>{
  //  setViewStaffDetails(record)
    setViewDetailsDialog(true);
   }
   const hideViewDetails=()=>{
    setViewDetailsDialog(false)
   }
   const showTransferDialog=(record)=>{
    setviewTransferDialog(true)
   }
   const hideTransferDialog=()=>{
    setviewTransferDialog(false)

   }

const [exam_names,setExamNames] = useState([
    { key: "pp", value: "पूर्व प्राथमिक" },
    { key: "p", value: "प्राथमिक" },
    { key: "s", value: "माध्यमिक" },
    { key: "hs", value: "उच्च माध्यमिक" }   
  ]);
  const [filled_by,setFilledBy] = useState([
    { key: "agent", value: "Agent" },
    { key: "parent", value: "Parent" },  
  ]);
  const [standard,setStandard] = useState([
    { key: "1", value: "1" },
    { key: "2", value: "2" },  
    { key: "2", value: "2" },  

  ]);
  // const exportToExcel = () => {
  //   fetch(`${Config.API_URL_LOCAL}exam/getAllExamFormsExcel`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok',response);
  //       }
  //       return response.blob();
  //     })
  //     .then((blob) => {
  //       if (blob.size === 0) {
  //         throw new Error('Received an empty Excel file');
  //       }
  //       const url = window.URL.createObjectURL(new Blob([blob]));
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'exported-data.xlsx';
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => console.error('Error exporting to Excel:', error));
  // };
  const exportToExcel = () => {
    fetch(`${Config.API_URL_LOCAL}exam/getAllExamFormsExcel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other necessary headers here
      },
      // If your API requires a body, include it here
      // body: JSON.stringify({ key: 'value' }), // Example body
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok', response);
      }
      return response.blob();
    })
    .then((blob) => {
      if (blob.size === 0) {
        throw new Error('Received an empty Excel file');
      }
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'exported-data.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => console.error('Error exporting to Excel:', error));
  };
  
  return (
    <div>
         <ToastContainer />
         <Dialog
        open={deleteDialogOpen}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteForm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={viewDetailsDialog}
        onClose={hideViewDetails}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Staff Details"}
        </DialogTitle>
        <DialogContent>
        <Box
        component="img"
        sx={{
          height: 233,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          mb: 5
        }}
        alt="Image Not Found"
      
      />
      
        </DialogContent>
        <DialogActions>
          <Button onClick={hideViewDetails}>Close</Button>
        
        </DialogActions>
      </Dialog>
      <Container alignItems="center" sx={{mt:1}}>

      <Grid 
               container 
               spacing={3} 
               mb={5}                               
               >
                <Grid item xs={12} md={3} lg={3}>
                <TextBox
          // values={school_section_list}
           label={"Agents "}
           id={"district"}
           name={"district"}
           autoFocus={true}             
          // onChange={(e)=>{setSelectedDistrict(e.target.value)}}   
           values={agents}
           //value={selectedDistrict}  
          isSelect={true}
        //  onKeyPress={handleKeypress}
             
          />
    </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                  {/* <Button variant="contained" color="primary" onClick={handleGoBack}>
          Submit
        </Button> */}
</Grid>



</Grid>
</Container>

<button onClick={exportToExcel}>Export</button>
     
       <DataTable
       columns={columns}
       Data={!loading? allExamForms.ExamFormHistory:[]}/>
        
    </div>
    
  )
}

export default ExamFormInfo