import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Paper } from '@mui/material';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import { Select, Input, Table, Button, Icon,Space } from "antd";

import { useDeletePhotoGalleryPhotosMutation, useGetGalleryPhotosQuery } from '../Services/modules/school';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Config } from '../Config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function GalleryPhotosInfo({ documentId, programId }) {
  const [galleryPhotosData, setGalleryPhotos] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const[showModal,setShowModal]=useState(false)
  const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)
 
  const[photoPathId,setPhotoPathIdForDelete]=useState("")

  const { data, isLoading, isSuccess, isError } = useGetGalleryPhotosQuery({
    documentId: documentId,
    programId: programId
  });
  const [DeletePhotoGalleryPhotos, { 
    data:deletePhotoGalleryPhotosData,
     isSuccess:deletePhotoGalleryPhotosIsSuccess, 
     isLoading:deletePhotoGalleryPhotosIsLoading=false, 
     isError:deletePhotoGalleryPhotosIsError, 
     error:deletePhotoGalleryPhotosError }] = useDeletePhotoGalleryPhotosMutation();  


  useEffect(() => {
    if (isSuccess) {
      console.log("data in display gallery photos", data);
      if (data && data.program) {
        setGalleryPhotos(data.program.photo_url);
      } else {
        console.log("Data or data.program is undefined");
      }
    }
  }, [data]);
  useEffect(() => {   
     
    if (deletePhotoGalleryPhotosIsSuccess) {         
      console.log("data",deletePhotoGalleryPhotosData)    
      toast.success(deletePhotoGalleryPhotosData.message)  
                   
    }
    else {
     
    console.log("In error")
       
    }
}, [deletePhotoGalleryPhotosIsLoading]);

  const deletePhotoConfirmAlert=(record)=>{
    console.log("record of delete",record)
    //dispatch(clearStaffData())
    setDeleteDialogOpen(true)
    setPhotoPathIdForDelete(record._id)

   }
   const deletePhoto=()=>{
    console.log("in delete photo",documentId)
    console.log("in delete photo programId",programId)
    DeletePhotoGalleryPhotos({
      documentId:documentId,
      programId:programId,
      photoPathId:photoPathId
      
    })
    setDeleteDialogOpen(false)

   }
   const closeConfirmAlert=()=>{
    
    setDeleteDialogOpen(false)

   }

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true)
  };

  const handleCloseModal = () => {
    setShowModal(false)
    setSelectedImage("");
   
  };

  console.log("galleryPhotosData", galleryPhotosData);

  return (
    <>
      <ToastContainer />
     
     <Dialog
        open={deleteDialogOpen}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deletePhoto} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    
      {
      galleryPhotosData.length > 0 ? 
      (
        <Grid container spacing={2}>
          {
          galleryPhotosData.map(
            (image, index) => 
                    
            (
            <Grid item xs={3} key={index}>
              <Paper>
                <img
                 // src={`http://localhost:8000/PhotoGallery/${image.photo_path}`}
                 src={`${Config.API_URL_IMAGE}/PhotoGallery/${image.photo_path}`}

                  alt={`Image ${index}`}
                  style={{ width: '100%', cursor:'pointer', height: '150px', objectFit: 'cover' }}
                  onClick={() => handleImageClick(image)}
                />
                <IconButton onClick={() => deletePhotoConfirmAlert(image)}>
                  <DeleteIcon />
                </IconButton>
              </Paper>
            </Grid>
            )
            
          
          )
         
          }
         
         <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {
            selectedImage !== "" && (
              <div style={{ 
                display: 'flex', 
              justifyContent: 'center',
               alignItems: 'center', height: '100%' }}>
                <img
                  src={`${Config.API_URL_IMAGE}/PhotoGallery/${selectedImage.photo_path}`}
                  alt={`Selected Image`}
                  style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'contain' }}
                />
              </div>
            )
            }
        </Box>
      </Modal>
        
          {/* <Modal 
          open={true} 
          onClose={handleCloseModal}>
            <div style={{ position: 'fixed', top: 10, right: 10 }}>
              <IconButton onClick={()=>handleCloseModal()}>
                <CloseIcon />
              </IconButton>
            </div>
            {
            selectedImage !== "" && (
              <div style={{ 
                display: 'flex', 
              justifyContent: 'center',
               alignItems: 'center', height: '100%' }}>
                <img
                  src={`http://localhost:8000/PhotoGallery/${selectedImage.photo_path}`}
                  alt={`Selected Image`}
                  style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'contain' }}
                />
              </div>
            )
            }
           
          </Modal> */}
        </Grid>
      ) : <Grid></Grid>}
    </>
  );
}

export default GalleryPhotosInfo;