import React from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
//import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import { Link,useLocation } from 'react-router-dom';


function SidebarMenusAdmin() {
    const location = useLocation();
  return (
    <>
     <React.Fragment>
     <Link to='/'
     style={{textDecoration:'none'}}
     >
    <ListItemButton
      style={location.pathname === '/dashboard' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}}
    >
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText 
      primary="Dashboard"
      
       />
    </ListItemButton>
    </Link>
    <Link 
    to='/teachingstaff'
    style={{textDecoration:'none'}}
    >
    <ListItemButton
      style={location.pathname === '/teachingstaff' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}}
    >
      <ListItemIcon>
      <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="कार्यरत शिक्षक" />
     
    </ListItemButton>
    </Link>
    <Link to='/retireteachingstaff' 
    style={{textDecoration:'none'}}>
    <ListItemButton
      style={location.pathname === '/retireteachingstaff' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}}
    >
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="माजी शिक्षक" />
    </ListItemButton>
    </Link>
   
    <Link to='/reports' 
    style={{textDecoration:'none'}}>
    <ListItemButton
      style={location.pathname === '/reports' ?{backgroundColor:'#f0f0f0'}:{backgroundColor:'white'}}
    >
    <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>  
      <ListItemText primary="Reports" />        
    </ListItemButton> 
    </Link>
    <Link 
    to='/galleryyear'
    style={{textDecoration:'none'}}
    >
    <ListItemButton
      style={location.pathname === '/galleryyear' ||
      location.pathname === '/galleryevent' ||
      location.pathname === '/galleryphotos'       
      ?{backgroundColor:'#f0f0f0'}:
      {backgroundColor:'white'}}
    >
      <ListItemIcon>
      <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Photo Gallery" />
     
    </ListItemButton>
    </Link>
    
  </React.Fragment>
   
    </>
  )
}

export default SidebarMenusAdmin