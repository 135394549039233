import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'photoGalleryEventData',
    initialState: { photo_gallery_event_data: null },
    reducers: {
        updatePhotoGalleryEventData: (state, { payload }) => {
            console.log("In photoGalleryData slice",payload)
            state.photo_gallery_event_data = payload
        },
        clearPhotoGalleryEventData: (state, { }) => {
            state.photo_gallery_event_data = null
        },
    },
})

export const { updatePhotoGalleryEventData, clearPhotoGalleryEventData } = slice.actions

export default slice.reducer  
