import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'staffData',
    initialState: { staff_data: null },
    reducers: {
        updateStaffData: (state, { payload }) => {
            console.log("In staff slice",payload)
            state.staff_data = payload
        },
        clearStaffData: (state, { }) => {
            state.staff_data = null
        },
    },
})

export const { updateStaffData, clearStaffData } = slice.actions

export default slice.reducer
