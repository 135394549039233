import React from 'react'
import { useSelector } from 'react-redux'
import { 
    Route,
    Routes,     
    Navigate,
    Outlet, } from 'react-router-dom'
import ActiveTeachingStaff from './Pages/ActiveTeachingStaff'
import Dashboard from './Pages/Dashboard'
import Login from './Pages/Login'
import ReportActiveTeachingStaff from './Pages/ReportActiveTeachingStaff'
import ReportsLandingPage from './Pages/ReportsLandingPage'
import RetireTeachingStaff from './Pages/RetireTeachingStaff'
import ExamLanding from './Pages/ExamLanding'
import ExamFormReport from './Pages/ExamFormReport'
import ExamDistrict from './Pages/ExamDistrict'
import ExamTaluka from './Pages/ExamTaluka'
import ExamSchoolName from './Pages/ExamSchoolName'
import GalleryYear from './Pages/GalleryYear'
import GalleryEvent from './Pages/GalleryEvent'
import GalleryPhotos from './Pages/GalleryPhotos'
import ExamAgent from './Pages/ExamAgent'
import ReportTse from './Pages/ReportTse'

function AppRoutes() {
  const isLoggedin = useSelector(state => state.userData.user_data == null ? false : true);
  const checkdata = useSelector(state => state.userData.user_data);
  console.log("Cjeck data",checkdata)
 
    const ProtectedRoute = ({
        isAllowed,
        redirectPath = '/',
        children,
      }) => {
        if (!isAllowed) {
          return <Navigate to={redirectPath} replace />;
        }
      
        return children ? children : <Outlet />;
      };
  return (
    <>
   
    <Routes>
        <Route index element={<Login/>}/>
        <Route element={<ProtectedRoute isAllowed={isLoggedin} />}>
        <Route path="dashboard" element={<Dashboard/>}/>
        <Route path="/teachingstaff" element={<ActiveTeachingStaff/>}/>
        <Route path="/retireteachingstaff" element={<RetireTeachingStaff/>}/>
        <Route path="/reports" element={<ReportsLandingPage/>}/>
        <Route path="/reporttse" element={<ReportTse/>}/>

        <Route path="/reportsactiveteachingstaff" element={<ReportActiveTeachingStaff/>}/>
        <Route path="/examlanding" element={<ExamLanding/>}/>
        <Route path="/examforms" element={<ExamFormReport/>}/>
        <Route path="/examdistrict" element={<ExamDistrict/>}/>
        <Route path="/examtaluka" element={<ExamTaluka/>}/>
        <Route path="/examschoolname" element={<ExamSchoolName/>}/>
        <Route path="/galleryyear" element={<GalleryYear/>}/>
        <Route path="/galleryevent" element={<GalleryEvent/>}/>
        <Route path="/galleryphotos" element={<GalleryPhotos/>}/>
        <Route path="/createagent" element={<ExamAgent/>}/>


        </Route>
    </Routes>
    </>
  )
}

export default AppRoutes