import { Grid, Paper } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import AdminTemplate from '../Components/Template/AdminTemplate'

function ReportsLandingPage() {
  const reportsLandingPageScreen=()=>{
    return (
      <div style={{marginTop:"50px"}}>
        
           <Grid 
              container 
              spacing={3} 
              mb={5}  
                            
              >
              
               
             <Grid item xs={12} md={4} lg={4}>
             <Link to='/reportsactiveteachingstaff' 
    style={{textDecoration:'none'}}>
                  <Paper
                    sx={{
                      p: 10,
                      display: 'flex',
                      flexDirection: 'column',                    
                     
                    }}
                    style={{
                      display:'flex',
                      textAlign:'center',
                      alignItems:'center'
                    }}
                  >
                  कार्यरत शिक्षक
                  {/* Total Schools:  */}
                  </Paper>
                  </Link>
                </Grid>
                
              
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 10,
                      display: 'flex',
                      flexDirection: 'column',
                     
                    }}
                    style={{  display:'flex',
                    textAlign:'center',
                    alignItems:'center'}}
                  >
                    माजी शिक्षक                    
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 10,
                      display: 'flex',
                      flexDirection: 'column',
                     
                    }}
                    style={{  display:'flex',
                    textAlign:'center',
                    alignItems:'center'}}
                  >
                   माजी विद्यार्थी 
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 10,
                      display: 'flex',
                      flexDirection: 'column',
                     
                    }}
                    style={{  display:'flex',
                    textAlign:'center',
                    alignItems:'center'}}
                  >
                   माजी विद्यार्थी 
                  </Paper>
                </Grid>
               
              </Grid>
      </div>
    )
  }
  return(
    <AdminTemplate>
      {reportsLandingPageScreen()}
    </AdminTemplate>
  )
  
}

export default ReportsLandingPage