import { Box, CardMedia } from '@mui/material'
import React from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { Config } from '../Config';


function SchoolProfileInfo({school_info}) {
const school_data = useSelector(state => state.schoolData.school_data);
console.log("schooldata for image",school_data)
  return (
    <div>
        <Box
        component="img"
        sx={{
          height: 233,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          mb: 5
        }}
        alt="Image Not Found"
       // src={`http://localhost:8000/SchoolPhoto/${school_data.school_photo_url}`}
         src={`${Config.API_URL_IMAGE}/SchoolPhoto/${school_data.school_photo_url}`}

      />
         <Box sx={{width: "auto", mt: 1 }}>
         स्थापना तारीख: {school_data.establishment_date}
    </Box>
    <Box sx={{width: "auto", mt: 1 }}>
    मुख्याध्यापकांचे नाव: {school_data.principal_name}
    </Box>
    <Box sx={{width: "auto", mt: 1 }}>
    विद्यार्थी संख्या: {school_data.total_students}
    </Box>
    <Box sx={{width: "auto", mt: 1 }}>
    पत्ता: {school_data.address}
    </Box>
    <Box sx={{width: "auto", mt: 1 }}>
    दूरध्वनी क्रमांक: {school_data.contact_number} 
    </Box>
    <Box sx={{width: "auto", mt: 1 }}>
    शाळेची माहिती: {school_data.school_description} 
    </Box>
   
    {/* <img
     src={`http://localhost:8000/SchoolPhoto/${school_data.school_photo_url}`}
   
    /> */}

    </div>
    
  )
}

export default SchoolProfileInfo