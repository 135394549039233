export default build =>
    build.query({
        tagTypes: ['allForms'],
        keepUnusedDataFor: 1,
        query() {
            //console.log("payload",payload)            
            return {
                url: '/exam/getAllExamForms',
                method: 'GET',
               // body: payload,
            };
           
        },
       providesTags: ['allForms']
    })
