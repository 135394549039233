import { Grid,DialogTitle,Typography, Box, Button, IconButton, TextField } from '@mui/material'
import React,{ useState,useEffect, useRef } from 'react'
import SubmitButton from './InputFields/SubmitButton'
import TextBox from './InputFields/TextBox'
import { useSelector,useDispatch } from 'react-redux'
import { useCreateGalleryEventNameMutation, useCreateGalleryYearMutation, useSchoolBySectionMutation, useUpdateGalleryEventNameMutation } from '../Services/modules/school'
import { useCreateStaffMutation,useUpdateStaffMutation } from '../Services/modules/staff'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import updateStaff from '../Services/modules/staff/updateStaff'

import { clearPhotoGalleryEventData } from '../Store/PhotoGalleryEventData'

function GalleryEventForm({gallery_id}) { 
  const dispatch = useDispatch();
  useEffect(()=>{  
    dispatch (clearPhotoGalleryEventData())
   },[])
  
let school_id=""  

const photo_gallery_event_data=useSelector(state => state.photoGalleryEventData.photo_gallery_event_data)

const[isupdateRecord,setUpdateRecord]=useState(false);     
const[eventName,setEventName]=useState('')  
const[event_id,setEventId]=useState('')
const [isFormValid, setIsFormValid] = useState(false);
const userRole=useSelector(state => state.userData.user_data.role)

var global_school_id=useSelector(state => state.userData.user_data.school_id)
//console.log('photo_gallery_data',photo_gallery_data)
if(global_school_id!==undefined)
{
school_id=global_school_id
}

const [CreateGalleryEventName, 
    { data, isSuccess, isLoading, isError, error }]
     = useCreateGalleryEventNameMutation();
  
       const [UpdateGalleryEventName, { 
        data:updateEventNameData,
         isSuccess:updateEventNameIsSuccess, 
         isLoading:updateEventNameIsLoading=false, 
         isError:updateEventNameIsError, 
         error:updateEventNameError }] = useUpdateGalleryEventNameMutation();   


     useEffect(() => {
       
        validateForm();
      }, [eventName]);
    useEffect(() => {   
     
      if (isSuccess) {         
        console.log("data",data)   
        if(data.error) 
        {
          toast.error(data.message) 
        }
        if(!data.error)
        {
          toast.success(data.message)        
        }
                  
      }
      else {
      console.log("In error")
         
      }
  }, [isLoading]);
  useEffect(() => {   
     
    if (updateEventNameIsSuccess) {         
      console.log("data",updateEventNameData)   
      if(updateEventNameData.error) 
      {
        toast.error(updateEventNameData.message) 
      }
      if(!updateEventNameData.error)
      {
        toast.success(updateEventNameData.message)        
      }
                
    }
    else {
    console.log("In error")
       
    }
}, [updateEventNameIsLoading]);
 
  useEffect(() => {
    if(photo_gallery_event_data!==null)
    {
    
      console.log("photo_gallery_event_data",photo_gallery_event_data)
      //alert("In staff data")
      setUpdateRecord(true)
      setEventId(photo_gallery_event_data._id)
     setEventName(photo_gallery_event_data.program_name)
      
    }
    else
    {
      setUpdateRecord(false)
      setEventId('')
      setEventName('')
     
    }
   
  },[photo_gallery_event_data])
    const validateForm = () => {
     
    
          if (eventName !== '') {
            setIsFormValid(true);
          } 
          else 
          {           
            setIsFormValid(false);
          }       
    };   
    const clearForm=()=>{
     
     setEventName('')
    }
   
  
    const GalleryEventFormScreen=()=>{

        function onSubmit (){  
     let payload={
      eventName:eventName,
      documentId:gallery_id,
      programDetailsId:event_id,
      role:userRole,
      school_id:school_id
     }         
     if(isupdateRecord)
     {
      UpdateGalleryEventName(payload)
      clearForm() 
     }
     else
     {
      CreateGalleryEventName(payload)    
      clearForm() 
     }      
             
         }
         
 
        return (
         
    <>
     <ToastContainer />
    
    <Grid container spacing={2} >
  
            <Typography component="h1" variant="h6">
           Add Event Name
                    </Typography>
            
  <Grid container item xs={12} direction="column" >
  
             <TextBox
             
               label={"कार्यक्रमाचे नाव लिहा"}
               id={"eventName"}
               name={"eventName"}
              isSelect={false}
              value={eventName}  
              onChange={(e)=>{setEventName(e.target.value)}}
              />
 
         
               <Grid 
            container 
            spacing={3}                 
            >
               <Grid item xs={12} md={2} lg={3}>  
              <SubmitButton 
              caption={isupdateRecord? "update": "Save"} 
             isLoading={isLoading}
             disabled={!isFormValid}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
              </Grid>
              <Grid item xs={12} md={2} lg={3}>  

               <SubmitButton 
              caption={"Reset"} 
              button_color={'red'}
             
              onClick={()=>clearForm()}
              />
              </Grid>
              </Grid>
             
           
  </Grid>
  
</Grid>

</>
         
          )
    }

    return(
        GalleryEventFormScreen()
    )
 
}

export default GalleryEventForm