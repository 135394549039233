export default build =>
    build.mutation({
        query(payload) {
            console.log("payload in delete district",payload)
            return {
                url: '/exam/deleteAgent',
                method: 'POST',
                body: payload,
               
            };
        },
        invalidatesTags: ['allAgents'],
    })
