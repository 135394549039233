import { api } from '../../api'

import login from './login'


export const loginApi = api.injectEndpoints({
    endpoints: build => ({
        Login: login(build),       
    }),
    overrideExisting: true,
})
export const { useLoginMutation } = loginApi
//export const { useLoginMutation, useLogoutMutation, useSignUpMutation, useChangePasswordMutation, useVerifyOTPMutation, useCheckMobileMutation } = loginApi
