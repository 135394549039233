export default build =>
    build.query({
        tagTypes: ['galleryPhotos'],
        keepUnusedDataFor: 1,
        query(payload) {
            //console.log("payload",payload)            
            return {
                url: `/school/getGalleryPhotos?documentId=${payload.documentId}&programId=${payload.programId}`,                        
                method: 'GET',
                //body: payload,
            };
           
        },
       providesTags: ['galleryPhotos']
    })
