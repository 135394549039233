import { Grid, Paper,Container } from '@mui/material'
import React from 'react'
import SchoolProfileForm from './SchoolProfileForm'
import SchoolProfileInfo from './SchoolProfileInfo'

function SchoolProfile() {
  return (
    <>
    <Container alignItems="center" sx={{mt:2,mb:2}}>
   <Grid
  container
  spacing={5}
  direction="row"
  alignItems="center"
  justify="center"
  //style={{ minHeight: '100vh' }}
>
         <Grid item xs={12} md={5} lg={5}>
         <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: "auto",
                  }}
                >
                    <SchoolProfileForm/>
                </Paper>
         </Grid>
         <Grid item xs={12} md={7} lg={7}>
         <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: "auto",
                  }}
                >
                    <SchoolProfileInfo/>
                </Paper>
         </Grid>

        </Grid>
        
        </Container>
       
        </>
  )
}

export default SchoolProfile