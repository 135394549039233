import React, { useState,useEffect } from "react";
import AdminTemplate from "../Components/Template/AdminTemplate";
import { useCreateAgentMutation, useDeleteAgentMutation, useGetAgentsQuery } from "../Services/modules/exam";
import DataTable from "../Components/DataTable";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Select, Input, Table, Icon,Space } from "antd"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { Grid,DialogTitle,Typography, Box, Button, IconButton, TextField } from '@mui/material'

function ExamAgent() {
   
    const[agent_mobile_number,setAgentMobileNumber]=useState("")
    const[agent_full_name,setAgentFullName]=useState("")
    const[isUpdate,setIsUpdate]=useState(false)
    const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)
    const[agent_id,setAgentId]=useState("")
    const [isFormValid, setIsFormValid] = useState(false);

  const [CreateAgent, { data, isSuccess, isLoading, isError, error }] =
    useCreateAgentMutation();
    const [DeleteAgent, 
        { data:deleteData, 
        isSuccess:deleteSuccess, 
        isLoading:deleteLoading,
         isError:deleteError,
           }] =
    useDeleteAgentMutation();
    const { 
        data: allAgents= [],  
        isLoading:loading=true,
        isSuccess:success,
        isError:errorAgents=false
       } = useGetAgentsQuery();

    useEffect(()=>{
        if(isSuccess)
        {
          if(data.error==true)
          {
            toast.error(data.message)
          }
          else
          {
            toast.success("Agnet added successfully")

          }
        }
    },[data])
    useEffect(() => {   
     
        if (!loading) {         
          console.log("data",allAgents)    
                    
        }
       
    }, [!loading]);
    useEffect(() => {
        validateForm();
      }, [agent_full_name,agent_mobile_number]);
    const validateForm = () => {
     if (agent_mobile_number !== '' ) {
        setIsFormValid(true);
    }
    else
    {
        setIsFormValid(false);

    }
}

  const ExamAgentScreen = () => {
    var columns=[
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Space size="middle">            
               
               {/* <a onClick={()=>editAgent(record)} style={{color: "blue"}}><EditIcon style={{fontSize:'medium'}}/> </a> */}
              
                <a onClick={()=>openDeleteAgentDialog(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>          
              </Space>
            ),
          },
        { 
            key: "full_name", 
            title: "Agent Name", 
            dataIndex: "full_name", 
            },
            { 
                key: "mobile_number", 
                title: "Mobile Number", 
                dataIndex: "mobile_number", 
                },
                { 
                  key: "password", 
                  title: "Mobile Number", 
                  dataIndex: "password", 
                  }
       ]
    const handleKeypress = e => {
    
        if (e.keyCode === 13) {
          onSubmit();
        }
      };
      const onSubmit=()=>{
        let payload={
            mobile_number:agent_mobile_number,
           // full_name:agent_full_name
        }
        CreateAgent(payload)
      }
      const openDeleteAgentDialog=(record)=>{   
        setDeleteDialogOpen(true)      
       setAgentId(record._id)
       }
       const closeConfirmAlert=()=>{
    
        setDeleteDialogOpen(false)
    
       }
       const deleteAgent=()=>{   
        setDeleteDialogOpen(false)
        DeleteAgent({agent_id:agent_id})
        if(deleteSuccess)
        {
          setAgentId("")
    toast.success(deleteData.message)
        }
       }
    return (
    <>
    <ToastContainer/>
     <Dialog
        open={deleteDialogOpen}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteAgent} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2} >
    <Grid container item xs={6} direction="column" >
 
 <Typography component="h1" variant="h6">
 Add Agents
          </Typography>
  <TextBox
          
             label={"Agent Mobile Number "}
             id={"agent_mobile_number"}
             name={"agent_mobile_number"}
             autoFocus={true}             
             onChange={(e)=>{setAgentMobileNumber(e.target.value)}}   
             value={agent_mobile_number}  
            isSelect={false}
            onKeyPress={handleKeypress}
               
            />
             {/* <TextBox
            // values={school_section_list}
             label={"Agent Name "}
             id={"agent_full_name"}
             name={"agent_full_name"}
             autoFocus={false}             
             onChange={(e)=>{setAgentFullName(e.target.value)}}   
             value={agent_full_name}  
            isSelect={false}
            disabled={!isFormValid}
            onKeyPress={handleKeypress}               
            /> */}
         
             <Grid 
          container 
          spacing={3}                 
          >
             <Grid item xs={12} md={2} lg={3}>  
            <SubmitButton 
             caption={isUpdate? "update": "Save"}           
            button_color={'green'}
            disabled={!isFormValid}

            isLoading={!isUpdate? isLoading: isLoading}
            onClick={()=>onSubmit()}
            />
            </Grid>
         
            </Grid>                    
</Grid>
<Grid container item xs={6} direction="column" >
 
 <DataTable columns={columns}
       Data={!loading? allAgents?.data:[]}/>      
</Grid>
</Grid>
    </>
    );
  };
  return (
    <>
      <AdminTemplate>{ExamAgentScreen()}</AdminTemplate>
    </>
  );
}

export default ExamAgent;
