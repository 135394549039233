export default build =>
    build.mutation({
        query(payload) {
            console.log("payload IN DELETE PHOTO GALLERY Photos",payload)
            return {
                url: '/school/deleteGalleryPhotos',
                method: 'POST',
                body: payload,
               
            };
        },
        invalidatesTags: ['galleryPhotos'],
    })
