import React,{useState} from 'react'
import { Select, Input, Table, Button, Icon,Space } from "antd";
//import ExportToExcel from './ExportToExcel';
function DataTable({columns,Data}) {
  const pageSize = 10;
  const currentPage = Data?.pagination ? Data?.pagination?.current : 1; // Use page 1 if pagination is not available
  const startIndex = (currentPage - 1) * pageSize + 1;
  const [searchText, setSearchText] = useState('');
  const filteredData = Data?.filter((item) => {
    // Combine all fields into a single string to search
    const combinedText = Object.values(item).join(' ');

    // Normalize both the search text and the combined text to lowercase
    const normalizedSearchText = searchText.toLowerCase();
    const normalizedCombinedText = combinedText.toLowerCase();

    // Check if the combined text contains the normalized search text
    return normalizedCombinedText.includes(normalizedSearchText);
  });
    const updatedColumns = [
      {
        title: 'Index',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => startIndex + index,
      },
      // {
      //   title: 'Agent Name',
      //   dataIndex: 'full_name',
      //   key: 'full_name',
      //   render: (text, record, index) => record.user?.full_name,
      // },
      ...columns, // Add the original columns
    ];
  
      // Add the index manually to each data item
      const dataWithIndex = Data?.map((item, index) => ({
        ...item,
        index: startIndex + index,
      }));
      const rowClassName = (record) => {
        console.log('Checking record for exported row:', record);
        return record.is_exported ? 'exported-row' : '';
      };
      return (
        <div>
             <input
        type="text"
        placeholder="Search..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
       {/* <ExportToExcel data={Data} columns={columns} fileName="exported-data.xlsx" /> */}
             <Table
               //  columns={columns}
                 
                columns={updatedColumns}
                //dataSource={Data.map((item, index) => ({ ...item, index: index + 1 + pageIndexOffset }))}
               //  dataSource={Data}
              // dataSource={dataWithIndex}
              rowClassName={rowClassName}
              dataSource={filteredData}
                // className="text-lg"            
                 scroll={{ y: 450, x: 500 }}
                
               //  pagination={Data.pagination}
                
               />   
        </div>
      )
    
   
 
}

export default DataTable