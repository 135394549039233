import React,{useEffect,useState,useRef} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import TextBox from '../../Components/InputFields/TextBox';
import SubmitButton from '../../Components/InputFields/SubmitButton';

import DataTable from '../../Components/DataTable';
import { useDispatch } from 'react-redux';
import { updateSchoolData } from '../../Store/SchoolData';

import { useGetAllSchoolsQuery,useCreateSchoolMutation,useUpdateSchoolMutation,useDeleteSchoolMutation, useMailSchoolCredentialsMutation } from '../../Services/modules/school';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import DrawerSchoolProfile from '../../Components/Template/DrawerSchoolProfile';
import AdminTemplate from '../../Components/Template/AdminTemplate';
import SchoolProfileForm from '../../Components/SchoolProfileForm'
import SchoolProfile from '../../Components/SchoolProfile';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';
import Mail from '@mui/icons-material/Mail';
import mailSchoolCredentials from '../../Services/modules/school/mailSchoolCredentials';

function SuperadminDashboard() {
    const dispatch = useDispatch();
    const [CreateSchool, { data, isSuccess, isLoading, isError, error }] = useCreateSchoolMutation();
    const [UpdateSchool, { 
      data:updateSchoolInfo,
       isSuccess:updateSchoolIsSuccess, 
       isLoading:updateSchoolIsLoading=false, 
       isError:updateSchoolIsError, 
       error:updateSchoolError }] = useUpdateSchoolMutation(); 

       const [DeleteSchool, { 
        data:deleteSchoolData,
         isSuccess:deleteSchoolIsSuccess, 
         isLoading:deleteSchoolIsLoading=false, 
         isError:deleteSchoolIsError, 
         error:deleteSchoolError }] = useDeleteSchoolMutation(); 
         
         const [SendMailSchoolCredetials, { 
          data:resMailData,
           isSuccess:mailSuccess, 
           isLoading:loadingMailSending=false, 
          
           error:errMailSending }] = useMailSchoolCredentialsMutation(); 
           

    const timestampRef = useRef(Date.now()).current;
    const [schoolProfileTitle,setSchoolProfileTitle]=useState("")
    const { 
      data: schools= [],  
      isLoading:loading=true,
      isSuccess:getSchoolSuccess=false
     } = useGetAllSchoolsQuery();
    //  const { 
    //   data: retireTeachingStaffCount= [],  
    //   isLoading:retireTeachingStaffCountloading=true,
    //   isSuccess:retireTeachingStaffCountSuccess=false
    //  } = useGetAllSchoolsQuery();
    
      console.log("All Schools",schools.data)
      console.log("Alumni Count",schools.staff_count)
const[totalStudentCount,setTotalStudentCount]=useState(0);
    const [state, setState] =useState({
      school_section: "",
      school_name:"",
      medium:"",
      school_id:""
    })
    const [validateInput, setValidateInput] =useState({
      school_section: "",
      school_name:"",
      medium:"",
      school_id:""
    })
    
    const [profileDrawerOpen, setprofileDrawerOpen]=useState(false)
    const[isupdateRecord,setUpdateRecord]=useState(false);
 const[schoolidForDelete,setSchoolIdForDelete]=useState(false);
const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)
const[mailDialogOpen,setMailDialogOpen]=useState(false)
const[mail_school_username,setMailSchoolUsername]=useState("")
const[mail_school_password,setMailSchoolPassword]=useState("")
const[school_mail_id,setSchoolMailId]=useState("")
const[mail_school_name,setMailSchoolName]=useState("")

  const school_catagory = [
    { key: "pp", value: "पूर्व प्राथमिक" },
    { key: "p", value: "प्राथमिक" },
    { key: "s", value: "माध्यमिक" },
    { key: "hs", value: "उच्च माध्यमिक" }
  ];
  const school_medium = [
    { key: "english", value: "English" },
    { key: "marathi", value: "Marathi" },
   
  ];
  const columns = [ 
   
    { 
    key: "name", 
    title: "Name", 
    dataIndex: "name", 
    }, 
  
    { 
    key: "school_unique_id", 
    title: "PIN", 
    dataIndex: "school_unique_id", 
    }, 
    { 
    key: "school_unique_pass", 
    title: "Password", 
    dataIndex: "school_unique_pass", 
    }, 
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
        <a onClick={()=>openMailDialog(record)} style={{color:record.is_mail_sent?"green":"blue"}}><Mail style={{fontSize:'medium'}}/> </a>         
          <a onClick={()=>createSchoolProfile(record)} style={{color: "blue"}}>Profile </a> 
          <a onClick={()=>editSchool(record)} style={{color: "blue"}}><EditIcon style={{fontSize:'medium'}}/> </a>
          <a onClick={()=>deleteSchoolConfirmAlert(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>
        
        </Space>
      ),
    },
    
    ] 
    const createSchoolProfile=(record)=>{
     setSchoolProfileTitle("Create profile of school "+record.name)
      setprofileDrawerOpen(true)
  console.log("Record",record)
  dispatch(updateSchoolData(record))
  
    }
    const editSchool=(record)=>{
      console.log("Record",record)
      setUpdateRecord(true)
      setState({
        ...state,
        school_section:record.school_section,
        school_name:record.name,
        medium:record.medium,
        school_id:record._id
      })
        }
       
            const deleteSchoolConfirmAlert=(record)=>{
             
              setDeleteDialogOpen(true)
              setSchoolIdForDelete(record._id)
          
             }
             const deleteSchool=()=>{
              DeleteSchool({school_id:schoolidForDelete})
              setDeleteDialogOpen(false)
          
             }
             const closeConfirmAlert=()=>{
              
              setDeleteDialogOpen(false)
          
             }
             const openMailDialog=(record)=>{
              console.log("recordOfMail",record)
              setMailDialogOpen(true)
              setMailSchoolUsername(record.school_unique_id)
              setMailSchoolPassword(record.school_unique_pass)
              setMailSchoolName(record.name)
             }
             const closeMailDialog=()=>{
              
              setMailDialogOpen(false)
          
             }
      const clear=()=>{
        setUpdateRecord(false)
        setState({
          ...state,
          school_section:"",
          school_name:"",
          medium:""
        })
      }
      const validate=()=>{ 
       
        let validateStatus=true
    if(state.school_section=="")
    {
      setValidateInput(previousInputs => ({ ...previousInputs, school_section: "Require" }))  
      validateStatus= false 
    }
    if(state.school_name=="")
    {  
      setValidateInput(previousInputs => ({ ...previousInputs, school_name: "Require" }))  
      validateStatus= false 
    }
    if(state.medium=="")
    {  
      setValidateInput(previousInputs => ({ ...previousInputs, medium: "Require" }))  
      validateStatus= false 
    }
   
    return validateStatus
       }
    function onSubmit (){
      setValidateInput(previousInputs => ({ ...previousInputs, school_section: "" }))
      setValidateInput(previousInputs => ({ ...previousInputs, school_name: "" })) 
      setValidateInput(previousInputs => ({ ...previousInputs, medium: "" }))    

      console.log("In on submit",state)
      if(isupdateRecord)
      {
        var checkvalidate=  validate()
        if(checkvalidate)
        {
        UpdateSchool(state)
        }
      }
      else
      {
        var checkvalidate=  validate()
        if(checkvalidate)
        {
          CreateSchool(state)
         
        }
      }
     
      
   // }  
  
   }
   function onMailSubmit (){
   console.log("mail_school_username",mail_school_username)
   console.log("mail_school_pass",mail_school_password)
    console.log("In on submit",school_mail_id)
    if(school_mail_id=="")
    {
      return toast.error("Please enter email id")
    }
    else
    {
      SendMailSchoolCredetials(
        {to_email:school_mail_id,
          school_unique_id:mail_school_username,
          school_unique_pass:mail_school_password,
          school_name:mail_school_name
        })
    }
   
 }
  //  const validate=()=>{  
  //   let validateStatus=true
  // if(payload.email=="")
  // {
  // setValidateInput(previousInputs => ({ ...previousInputs, erremail: "Require" }))  
  // validateStatus= false 
  // }
  // if(payload.password=="")
  // {  
  // setValidateInput(previousInputs => ({ ...previousInputs, errpassword: "Require" }))  
  // validateStatus= false 
  // }
  // return validateStatus
  //  }
   
    function handleChange(e) {
      const value = e.target.value;
  console.log("In handle change",e)
      setState({
        ...state,
        [e.target.name]: value
      });
    }
    useEffect(() => {    
     
      if (isSuccess) {
          console.log("InSuccess",data);
         // GetAllSchools()
          if(data.error==true)
          {
            toast.error(data.message)
            return 
          }
          else
          {
            toast.success("School created successfully")
          }
          //dispatch(updateUserData({ data: data }))
         
      }
      if (isError) {
          alert("In error")
          console.log(error);
      }
  }, [isLoading]);
  useEffect(() => {   
     
    if (updateSchoolIsSuccess) {         
      console.log("data",updateSchoolInfo)    
      toast.success(updateSchoolInfo.message)  
      clear()
      setUpdateRecord(false)                 
    }
    else {
      clear()
      setUpdateRecord(false)   
    console.log("In error")
       
    }
}, [updateSchoolIsLoading]);
useEffect(() => {   
     
  if (deleteSchoolIsSuccess) {         
    console.log("data",deleteSchoolData)    
    toast.success(deleteSchoolData.message)  
                 
  }
  else {
   
  console.log("In error")
     
  }
}, [deleteSchoolIsLoading]);
useEffect(() => {   
     
  if (mailSuccess) {         
    console.log("data",resMailData)    
    toast.success(resMailData.message) 
    closeMailDialog() 
                 
  }
  else {
   
  console.log("In error")
     
  }
}, [loadingMailSending]);
useEffect(() => {   
  var student_count=0;
  if(getSchoolSuccess)
  {
     for(var i=0;i<schools.data.length;i++)
     {
       
        if(schools.data[i].total_students>0)
        {
        student_count+= parseInt(schools.data[i].total_students)
      console.log("Student Count",student_count)
      setTotalStudentCount(student_count)
        }
     }
    }
  
}, [getSchoolSuccess]);



  const dashboardScreen=()=>{
    return (
     
    <>
     <ToastContainer />
     <Dialog
        open={deleteDialogOpen}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this school record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteSchool} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={mailDialogOpen}
        onClose={closeMailDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Send Credentials To School"}
        </DialogTitle>
        <DialogContent>
        
        <Grid item xs={12} md={4} lg={5}>
               <Box sx={{width: "auto", mt: 1 }}>
              <TextBox
               values={school_catagory}
               label={"School Email Id"}
               id={"school_mail_id"}
               autoFocus={true}
               value={school_mail_id}
              onChange={(e)=>setSchoolMailId(e.target.value)}
              name={"school_mail_id"} 
              isSelect={false}
              />
               <SubmitButton 
              caption={"Send Mail"} 
              isLoading={loadingMailSending}
              button_color={'green'}
              onClick={()=>onMailSubmit()}
              />
              
              </Box>
            </Grid>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMailDialog}>Close</Button>
          {/* <Button onClick={deleteSchool} autoFocus>
            Yes
          </Button> */}
        </DialogActions>
      </Dialog>
     
     <DrawerSchoolProfile children={<SchoolProfile/>} title={schoolProfileTitle} isOpen={profileDrawerOpen}  setIsDrawerOpen={setprofileDrawerOpen}/>
     <Grid 
            container 
            spacing={3} 
            mb={5}                
            >
                <Grid item xs={12} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 70,
                    
                    
                  }}
                >
                एकूण शाळा: {!loading? schools.data.length:""}
                {/* Total Schools:  */}
                </Paper>
              </Grid>
            
              <Grid item xs={12} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 70,
                  }}
                >
                  संस्थेचे एकूण विद्यार्थी: {totalStudentCount}
                 
                
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 70,
                  }}
                >
                  एकूण कार्यरत शिक्षक: {schools.active_teaching_staff_count}                                 
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 70,
                  }}
                >
                  संस्थेचे एकूण माजी शिक्षक: {schools.alumni_count}
                
                </Paper>
              </Grid>
            </Grid>
            <Grid 
            container 
            spacing={3}                 
            >
            
              <Grid item xs={12} md={4} lg={5}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: "auto",
                  }}
                >
                 <Typography component="h1" variant="h6">
                 संस्थेच्या शाळा जोडा
            </Typography>
            <Box sx={{width: "auto", mt: 1 }}>
              <TextBox
               values={school_catagory}
               label={"विभाग"}
               id={"school_section"}
               autoFocus={true}
               value={state.school_section}
              onChange={handleChange}
              name={"school_section"} 
              isSelect={true}

              />
             <div style={{color:"red"}}>{validateInput.school_section}</div>

              <TextBox
               values={school_medium}
               label={"माध्यम"}
               id={"medium"}
              onChange={handleChange}
              name={"medium"} 
              value={state.medium}
              isSelect={true}

              />
             <div style={{color:"red"}}>{validateInput.medium}</div>

              <TextBox 
              id={"school_name"} 
              label={"शाळेचे नाव"} 
              name={"school_name"} 
              autoFocus={false}
              value={state.school_name}
              onChange={handleChange}
              />
             <div style={{color:"red"}}>{validateInput.school_name}</div>
  
               <Grid 
            container 
            spacing={3}                 
            >
               <Grid item xs={12} md={2} lg={3}>  
              <SubmitButton 
              caption={isupdateRecord?"update":"Save"} 
              isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                
              </Grid>
              <Grid item xs={12} md={2} lg={3}>  

               <SubmitButton 
              caption={"Reset"} 
              button_color={'red'}
               
              onClick={()=>clear()}
              />
              </Grid>
              </Grid>
            
            </Box>
                </Paper>
              </Grid>
             
              <Grid item xs={12} md={7} lg={7}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: "auto",
                  }}
                >
                  <DataTable 
                  columns={columns}
                  Data={!loading? schools.data:[]}/>
                  {/* <Deposits /> */}
                </Paper>
              </Grid>
            
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                 
                </Paper>
              </Grid>
             
           
            </Grid>
            </>
          
       
      
    )
  }
  return (
   
    <AdminTemplate>
  {dashboardScreen()}
  </AdminTemplate>
 
)
}

export default SuperadminDashboard