import React,{useEffect,useState,useRef} from 'react'

import { useDispatch,useSelector } from 'react-redux';
import DashboardSchool from './SchoolAdmin/DashboardSchool';

import SuperadminDashboard from './SuperAdmin/SuperadminDashboard';
//import { useCreateSchoolMutation } from '../Services/modules/school';

function Dashboard() {
  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);

  if(isSuperAdmin)
 { return (
   
     <SuperadminDashboard/>
   
  )}
  else{
    return (
   
      <DashboardSchool/>
    
   )
  }
}

export default Dashboard