import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import SchoolProfileInfo from '../SchoolProfileInfo';


function DrawerSchoolProfile({children,isOpen,setIsDrawerOpen,title}) {
    const [state, setState] = React.useState(isOpen);
    
      const toggleDrawer = () => (event) => {
        setIsDrawerOpen(!isOpen)
        //setState(!open);
      };
      const list = (anchor) => (
        <Box
          // sx={{ width:500 }}
          role="presentation"         
        >
         <DialogTitle disableTypography className="drawerTitle">
    <IconButton >
        <CancelIcon onClick={toggleDrawer()}/>
    </IconButton>
    {title}
  </DialogTitle>
  {children}
  {/* <SchoolProfileInfo/> */}
        </Box>
      );
    
  return (
    <div>
        
        <React.Fragment>         
          <Drawer
            anchor={"right"}
            open={isOpen}
            onClose={toggleDrawer()}
            PaperProps={{
              sx: { width: "60%" },
            }}
          >
            
            {list("left")}
          </Drawer>
        </React.Fragment>
     
    </div>
  )
}

export default DrawerSchoolProfile