export default build =>
    build.mutation({
        query(payload) {
            console.log("payload in create event name",payload)
            return {
                url: '/school/uploadGalleryPhotos',
                method: 'POST',
                body: payload,               
            };
        },
        invalidatesTags: ['galleryPhotos'],
    })
