import { Box, Grid } from '@mui/material'
import React,{useEffect,useState,useRef} from 'react'

import { useSelector,useDispatch } from 'react-redux'
import { useGetStaffQuery,useDeleteStaffMutation } from '../Services/modules/staff';
import DataTable from './DataTable';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { updateStaffData,clearStaffData } from '../Store/StaffData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextBox from './InputFields/TextBox';
import { useSchoolBySectionMutation } from '../Services/modules/school';

function StaffInfo({is_retire,staff_type,is_report}) {
    const dispatch=useDispatch()
  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);

const user_role = useSelector(state => state.userData.user_data.role);
const[viewStaffDetails,setViewStaffDetails]=useState({})
//alert(user_role)
var school_id=0
var global_school_id=useSelector(state => state.userData.user_data.school_id)

if(user_role=="school_admin")
{

    school_id=global_school_id
}
const { 
    data: allStaff= [],  
    isLoading:loading=true,
    isSuccess,
    isError
   } = useGetStaffQuery({school_id:school_id,is_retire:is_retire,staff_type:staff_type});
  
   const [DeleteStaff, { 
    data:deleteStaffData,
     isSuccess:deleteStaffIsSuccess, 
     isLoading:deleteStaffIsLoading=false, 
     isError:deleteStaffIsError, 
     error:deleteStaffError }] = useDeleteStaffMutation();  

     const[SchoolInfo,setSchoolInfo]=useState({
        transfer_date:"",
      school_id:school_id,
      school_section:"",
     
    })   
     const[SchoolBySection, {    
      data: schools= [],  
      isLoading:schoolLoading=false,
      isSuccess:successSchoolFetch=false,
      
     } ]= useSchoolBySectionMutation(); 
     const schoolSectionChange=(e)=>{
      setSchoolInfo({
        ...SchoolInfo,
        [e.target.name]: e.target.value,          
      })
   
    var payload = {
      schoolSection: e.target.value,         
  };
  SchoolBySection(payload)        
}
   var columns=[
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">            
            <a onClick={()=>showviewDetails(record)} style={{color: "blue"}}><RemoveRedEyeIcon style={{fontSize:'medium'}}/> </a> 
           {
           !is_report? 
           <a onClick={()=>editStaff(record)} style={{color: "blue"}}><EditIcon style={{fontSize:'medium'}}/> </a>:
           !is_retire && user_role=="super_admin"?<a onClick={()=>showTransferDialog(record)} style={{color: "blue"}}>Transfer Teacher </a>:""
           }
            <a onClick={()=>deleteStaffConfirmAlert(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>          
          </Space>
        ),
      },
    { 
        key: "name", 
        title: "Name", 
        dataIndex: "name", 
        },
        { 
            key: "birthdate", 
            title: "Bdate", 
            dataIndex: "birthdate", 
            }, 
            { 
                key: "school_name", 
                title: "School Name", 
                dataIndex: "school_name", 
                },   
            { 
                key: "joining_date", 
                title: "Jdate", 
                dataIndex: "joining_date", 
                }, 
               {
                    key: "retire_date", 
                title: "Rdate", 
                dataIndex: "retire_date",  
                },
                
   ]
   const [school_section_list,setSchoolSections] = useState([
    { key: "pp", value: "पूर्व प्राथमिक" },
    { key: "p", value: "प्राथमिक" },
    { key: "s", value: "माध्यमिक" },
    { key: "hs", value: "उच्च माध्यमिक" }
   
  ]);
const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)
const[staffIdForDelete,setStaffIdForDelete]=useState("")
const[viewDetailsDialog,setViewDetailsDialog]=useState(false);
  const[viewTransferDialog,setviewTransferDialog]=useState(false)
   const editStaff=(record)=>{
    console.log(record,"StaffRecord")
dispatch(updateStaffData(record))
   }
   const deleteStaffConfirmAlert=(record)=>{
    //dispatch(clearStaffData())
    setDeleteDialogOpen(true)
    setStaffIdForDelete(record._id)

   }
   const deleteStaff=()=>{
    DeleteStaff({staff_id:staffIdForDelete})
    setDeleteDialogOpen(false)

   }
   const closeConfirmAlert=()=>{
    
    setDeleteDialogOpen(false)

   }
   const showviewDetails=(record)=>{
    setViewStaffDetails(record)
    setViewDetailsDialog(true);
   }
   const hideViewDetails=()=>{
    setViewDetailsDialog(false)
   }
   const showTransferDialog=(record)=>{
    setviewTransferDialog(true)
   }
   const hideTransferDialog=()=>{
    setviewTransferDialog(false)

   }
   useEffect(() => {   
     
    if (deleteStaffIsSuccess) {         
      console.log("data",deleteStaffData)    
      toast.success(deleteStaffData.message)  
                   
    }
    else {
     
    console.log("In error")
       
    }
}, [deleteStaffIsLoading]);
  return (
    <div>
         <ToastContainer />
         <Dialog
        open={deleteDialogOpen}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteStaff} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={viewDetailsDialog}
        onClose={hideViewDetails}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Staff Details"}
        </DialogTitle>
        <DialogContent>
        <Box
        component="img"
        sx={{
          height: 233,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          mb: 5
        }}
        alt="Image Not Found"
       // src={`http://localhost:8000/StaffPhoto/${viewStaffDetails.photo}`}
      src={`https://backend.sanglishikshansanstha.com/StaffPhoto/${viewStaffDetails.photo}`}

       
      />
        <Grid item xs={12} md={12} lg={12}>
         Name: {viewStaffDetails.name}
         </Grid>
         <Grid item xs={12} md={12} lg={12}>
         Birth Date: {viewStaffDetails.birthdate}
         </Grid>
         <Grid item xs={12} md={12} lg={12}>
         Education: {viewStaffDetails.education}
         </Grid>
         <Grid item xs={12} md={12} lg={12}>
         Position: {viewStaffDetails.position}
         </Grid>
         <Grid item xs={12} md={12} lg={12}>
         Joining Date: {viewStaffDetails.joining_date}
         </Grid>
         <Grid item xs={12} md={12} lg={12}>
         Retire Date: {viewStaffDetails.retire_date}
         </Grid>
         <Grid item xs={12} md={12} lg={12}>
         School Name: {viewStaffDetails.school_name}
         </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideViewDetails}>Close</Button>
        
        </DialogActions>
      </Dialog>
      <Dialog
        open={viewTransferDialog}
        onClose={hideTransferDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"शिक्षकांची बदली करा"}
        </DialogTitle>
        <DialogContent>
        <Grid item xs={12} md={12} lg={12}>
        { 
    isSuperAdmin?
   <>
   
    <TextBox
               values={school_section_list}
               label={"विभाग"}
               id={"school_section"}
               name={"school_section"}
               autoFocus={true}             
              onChange={schoolSectionChange}   
              //value={Staff.school_section}  
              isSelect={true}
                 
              />
             {/* <div style={{color:"red"}}>{validateInput.school_section}</div> */}
             </>
              :""              
              }
               { 
    isSuperAdmin?
   <>
   
    <TextBox
               //values={schoolsData}
               label={"School Name"}
               id={"school_id"}
               name={"school_id"}
              isSelect={true}
            //  onChange={handleChange}
              //value={Staff.school_id}  
              autoFocus={false}
              />
             {/* <div style={{color:"red"}}>{validateInput.school_id}</div> */}

              </>
              :""              
              }
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideViewDetails}>Close</Button>
        
        </DialogActions>
      </Dialog>
    
       <DataTable
       columns={columns}
       Data={!loading? allStaff.data:[]}/>
        
    </div>
    
  )
}

export default StaffInfo