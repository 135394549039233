export default build =>
    build.mutation({
        query(payload) {
            console.log("payload IN DELETE PHOTO GALLERY YEAR",payload)
            return {
                url: '/school/deletePhotoGalleryYear',
                method: 'POST',
                body: payload,
               
            };
        },
        invalidatesTags: ['photogallery'],
    })
