import { Grid, Paper } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import AdminTemplate from '../Components/Template/AdminTemplate'

function ExamLanding() {
    const ExamLandingScreen=()=>{
        return(
            <div style={{marginTop:"50px"}}>
        
            <Grid 
               container 
               spacing={3} 
               mb={5}                       
               >
                       
              <Grid item xs={12} md={4} lg={4}>
              <Link to='/reporttse' 
        style={{textDecoration:'none',textAlign:'center'}}>
                   <Paper
                     sx={{
                       p: 2,
                       display: 'flex',
                       flexDirection: 'column',
                       height: 70,
                      
                     }}
                   >
                  भरलेले फॉर्म्स
                   {/* Total Schools:  */}
                   </Paper>
                   </Link>
                 </Grid>
                 
               
                 <Grid item xs={12} md={4} lg={4}>
                 <Link to='/examdistrict' 
        style={{textDecoration:'none',textAlign:'center'}}>
                 
                   <Paper
                     sx={{
                       p: 2,
                       display: 'flex',
                       flexDirection: 'column',
                       height: 70,
                     }}
                   >
                    जिल्हा              
                   </Paper>
                   </Link>
                 </Grid>
                 <Grid item xs={12} md={4} lg={4}>
                 <Link to='/examtaluka' 
        style={{textDecoration:'none',textAlign:'center'}}>
                   <Paper
                     sx={{
                       p: 2,
                       display: 'flex',
                       flexDirection: 'column',
                       height: 70,
                     }}
                   >
                   तालुका
                   </Paper>
                   </Link>
                 </Grid>
                 <Grid item xs={12} md={4} lg={4}>
                 <Link to='/examschoolname' 
        style={{textDecoration:'none',textAlign:'center'}}>
                   <Paper
                     sx={{
                       p: 2,
                       display: 'flex',
                       flexDirection: 'column',
                       height: 70,
                     }}
                   >
                   
                   जिल्हा निहाय शाळांची नावे
                  
                   </Paper>
                   </Link>
                 </Grid>
                 <Grid item xs={12} md={4} lg={4}>
                 <Link to='/createagent' 
        style={{textDecoration:'none',textAlign:'center'}}>
                   <Paper
                     sx={{
                       p: 2,
                       display: 'flex',
                       flexDirection: 'column',
                       height: 70,
                     }}
                   >
                   
                  Add Agents
                  
                   </Paper>
                   </Link>
                 </Grid>
                
               </Grid>
        </div>
        )
    }
  return (
  <AdminTemplate>
    {ExamLandingScreen()}
  </AdminTemplate>
  )
}

export default ExamLanding