import { Grid,Button  } from '@mui/material'
import React from 'react'
import StaffInfo from '../Components/StaffInfo'
import AdminTemplate from '../Components/Template/AdminTemplate'
import{useNavigate} from 'react-router-dom'
import ExamFormInfo from '../Components/ExamFormInfo'
function ReportTse() {
  let navigate=useNavigate()
    const ReportTseScreen=()=>{
      const handleGoBack = () => {
      navigate('/examlanding')
      };
        return(
            <div style={{marginTop:"50px"}}>
               <Grid 
               container 
               spacing={3} 
               mb={5}  
                             
               >
                 <Button variant="contained" color="primary" onClick={handleGoBack}>
          Go Back
        </Button>
               </Grid>
       
            <Grid 
               container 
               spacing={3} 
               mb={5}  
                             
               >
{/* <StaffInfo is_report={true} is_retire={false} staff_type={"teaching"}/> */}
<ExamFormInfo/>
</Grid>
</div>
        )
    }
  return (
    <AdminTemplate>
        {ReportTseScreen()}
    </AdminTemplate>
  )
}

export default ReportTse