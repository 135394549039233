export default build =>
    build.mutation({
        query(payload) {
            console.log("In get school by section",payload)
            return {
                url: '/exam/gettalukabydistrict',
                method: 'POST',
               // queryParam:payload
                body: payload,
               
            };
        },
        //invalidatesTags: ['allSchools'],
    })
