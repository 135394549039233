import { Grid, Paper,Container, Button } from '@mui/material'
import React, { useEffect,useState } from 'react'
import {useSelector } from 'react-redux'
import AdminTemplate from '../Components/Template/AdminTemplate'
import { useNavigate } from 'react-router-dom';
import GalleryPhotosForm from '../Components/GalleryPhotosForm'
import GalleryPhotosInfo from '../Components/GalleryPhotosInfo';

function GalleryPhotos() {
   
  const navigate = useNavigate();
const photo_gallery_event_data=useSelector(state => state.photoGalleryEventData.photo_gallery_event_data)
let programId=photo_gallery_event_data._id
let photo_gallery_data=useSelector(state => state.photoGalleryData.photo_gallery_data)
let gallery_id=photo_gallery_data._id
let year=photo_gallery_data.year
let event_name=photo_gallery_event_data.program_name
const[loading,setLoading]=useState(true)

useEffect(() => {
    console.log("photo_gallery_event_data",photo_gallery_event_data)
  if(photo_gallery_data && photo_gallery_event_data)
  {
    setLoading(false)
 

  }
  else
  {
    navigate('/galleryyear');

  }
});
const goToYear=()=>{
  navigate('/galleryyear');
}
const goToEvents=()=>{
  navigate('/galleryevent');
}
    const GalleryPhotosScreen=()=>{
        return(
      loading?<div>Loading...</div>: 

            <Container alignItems="center" sx={{mt:5}}>
                <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
         
           <Grid item xs={3} md={3} lg={3}>
           
            <Button
                onClick={()=>goToYear()}
                fullWidth
               // disabled={isLoading}
             
                variant="contained"
                sx={{ mt: 3, mb: 2,backgroundColor:"yellow",color:'black' }}

              >Go To Year</Button>
 </Grid>
 <Grid item xs={3} md={3} lg={3}>
                <Button
                onClick={()=>goToEvents()}
                fullWidth
               // disabled={isLoading}
             
                variant="contained"
                sx={{ mt: 3, mb: 2,backgroundColor:"yellow",color:'black' }}

              >Go Back
              </Button>
 </Grid>
         </Grid>
              Upload photos for {event_name}- {year}
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={12} lg={12}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                          
                         <GalleryPhotosForm gallery_id={gallery_id} programId={programId}/>
   
                         </Paper>
                  </Grid>
                
              
                 </Grid>
                 <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
            <Grid item xs={12} md={12} lg={12}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                          
                         <GalleryPhotosInfo documentId={gallery_id} programId={programId}/>
   
                         </Paper>
                  </Grid>
         </Grid>
                 </Container>
        )
    }
  return (
  <AdminTemplate>
    {GalleryPhotosScreen()}
  </AdminTemplate>
  )
}

export default GalleryPhotos