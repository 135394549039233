import { Box, Grid } from '@mui/material'
import React,{useEffect,useState,useRef} from 'react'
import { useSelector,useDispatch } from 'react-redux'

import DataTable from './DataTable';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { updateStaffData,clearStaffData } from '../Store/StaffData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDeletePhotoGalleryYearMutation, useGetPhotoGalleryByIdQuery, useGetPhotoGalleryQuery, useSchoolBySectionMutation } from '../Services/modules/school';
import { updatePhotoGalleryData } from '../Store/PhotoGalleryData';
import { useNavigate } from 'react-router-dom';
import { updatePhotoGalleryEventData } from '../Store/PhotoGalleryEventData';

function GalleryEventInfo({gallery_id}) {
  const navigate = useNavigate();
  let photo_gallery_data=useSelector(state => state.photoGalleryData.photo_gallery_data)
  let gallery_id_local=photo_gallery_data._id
const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)
const[documentId,setDocumentIdForDelete]=useState("")

    const dispatch=useDispatch()
  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);
const user_role = useSelector(state => state.userData.user_data.role);

var school_id=0
var global_school_id=useSelector(state => state.userData.user_data.school_id)
if(user_role=="school_admin")
{
    school_id=global_school_id
}
const { 
    data: allGallery= [],  
    isLoading:loading=true,
    isSuccess,
    isError
   } = useGetPhotoGalleryByIdQuery({documentId:gallery_id_local});
  //console.log('allGallery',allGallery)
   const [DeletePhotoGalleryYear, { 
    data:deleteYearData,
     isSuccess:deleteYearIsSuccess, 
     isLoading:deleteYearIsLoading=false, 
     isError:deleteYearIsError, 
     error:deleteYearError }] = useDeletePhotoGalleryYearMutation();  
console.log("allGallery",allGallery)
   var columns=[
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">            
           <a onClick={()=>addEventPhotosRedirect(record)} style={{color: "blue"}}>Add Photos </a>
           
           <a onClick={()=>editEventName(record)} style={{color: "blue"}}><EditIcon style={{fontSize:'medium'}}/> </a>
         
            <a onClick={()=>deleteYearConfirmAlert(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>          
          </Space>
        ),
      },
    { 
        key: "program_name", 
        title: "Year", 
        dataIndex: "program_name", 
        },
               
   ]
 
   const editEventName=(record)=>{
    console.log(record,"Event Record")
    dispatch(updatePhotoGalleryEventData(record))
//dispatch(updateStaffData(record))
   }
   const addEventPhotosRedirect=(record)=>{
    console.log(record,"StaffRecord")
    dispatch(updatePhotoGalleryEventData(record))
    navigate('/galleryphotos');
   
   }
   const deleteYearConfirmAlert=(record)=>{
    //dispatch(clearStaffData())
    setDeleteDialogOpen(true)
    setDocumentIdForDelete(record._id)

   }
   const deleteYear=()=>{
    DeletePhotoGalleryYear({documentId:documentId})
    setDeleteDialogOpen(false)

   }
   const closeConfirmAlert=()=>{
    
    setDeleteDialogOpen(false)

   }
  
  
   useEffect(() => {   
     
    if (deleteYearIsSuccess) {         
      console.log("data",deleteYearData)    
      toast.success(deleteYearData.message)  
                   
    }
    else {
     
    console.log("In error")
       
    }
}, [deleteYearIsLoading]);
  return (
    <div>
         <ToastContainer />
         <Dialog
        open={deleteDialogOpen}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteYear} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    
       <DataTable
       columns={columns}
       Data={!loading? allGallery.data.program_details.slice().reverse():[]}/>
        
    </div>
    
  )
}

export default GalleryEventInfo