export default build =>
    build.query({
        tagTypes: ['photogallerybyid'],
        keepUnusedDataFor: 1,
        query(payload) {
           
            console.log("in get gallery by id",payload)
            return {
                url: `/school/getPhotoGalleryById?documentId=${payload.documentId}`,           
                method: 'GET',
              //  body: payload,
               
            };
        },
        providesTags: ['photogallerybyid'],
    })
