import React,{ useState,useEffect, useRef } from 'react'
import { Grid,DialogTitle,Typography, Box, Button, IconButton, TextField } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import { useSelector,useDispatch } from 'react-redux'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import DataTable from '../Components/DataTable'
import { useCreateDistrictMutation, useCreateTalukaMutation, useDeleteDistrictMutation, useDeleteTalukaMutation, useGetDistrictQuery, useGetTalukaQuery, useUpdateDistrictMutation, useUpdateTalukaMutation } from '../Services/modules/exam'
import { api } from '../Services/api'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Select, Input, Table, Icon,Space } from "antd"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


function ExamTaluka() {
  const[taluka_id,setTalukaId]=useState("")

 const[districts,setDistrict]=useState([])
 const[talukaList,setTalukaList]=useState([])
 const[selectedDistrict,setSelectedDistrict]=useState([])
  const[taluka,setTaluka]=useState("")
  const[isUpdate,setIsUpdate]=useState(false)
const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)

  const [CreateTaluka, { data, isSuccess, isLoading, isError, error }] = useCreateTalukaMutation();   
  const [UpdateDistrict, { data:updateResponse, isSuccess:updateSuccess, isLoading:isUpdateLoading, isError:updateError}] = useUpdateDistrictMutation();   
  const [UpdateTaluka, { data:updateTalukaResponse, isSuccess:updateTalukaSuccess, isLoading:isUpdateTalukaLoading, isError:updateTalukaError}] = useUpdateTalukaMutation();   

  const [DeleteDistrict, { data:deleteResponse, isSuccess:deleteSuccess, isLoading:isDeleteLoading, isError:deleteError}] = useDeleteDistrictMutation();   
  const [DeleteTaluka, { data:deleteTalukaResponse, isSuccess:deleteTalukaSuccess, isLoading:isTalukaDeleteLoading, isError:deleteTalukaError}] = useDeleteTalukaMutation();   

  
const { 
    data: allDistricts= [],  
    isLoading:loading=true,
    isSuccess:success,
    isError:errorDistrict=false
   } = useGetDistrictQuery();
   const { 
    data: allTalukas= [],  
    isLoading:loadingTaluka=true,
    isSuccess:successTaluka,
    isError:errorTaluka=false
   } = useGetTalukaQuery();

  var columns=[
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">            
           
           <a onClick={()=>editTaluka(record)} style={{color: "blue"}}><EditIcon style={{fontSize:'medium'}}/> </a>
          
            <a onClick={()=>openDeleteTalukaDialog(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>          
          </Space>
        ),
      },
    { 
        key: "taluka_name", 
        title: "Taluka name", 
        dataIndex: "taluka_name", 
        },
        { 
          key: "district_name", 
          title: "District name", 
          dataIndex: "district_name", 
          }
   ]
 
  
   const editTaluka=(record)=>{
    console.log("In edit district",record)
    setTaluka(record.taluka_name)
    setTalukaId(record._id)
    setIsUpdate(true)
   }
   const closeConfirmAlert=()=>{
    
    setDeleteDialogOpen(false)

   }
   const openDeleteTalukaDialog=(record)=>{   
    setDeleteDialogOpen(true)
   // DeleteDistrict({district_id:record._id})
   setTalukaId(record._id)
   }
   const deleteTaluka=()=>{   
    setDeleteDialogOpen(false)
    DeleteTaluka({taluka_id:taluka_id})
    if(deleteSuccess)
    {
      setTalukaId("")
toast.success(deleteResponse.message)
    }
   }
 
    useEffect(() => {   
      var districtlist=[{
        key:"",
        value:""
      }]     
     
        if (success) {         
         // console.log(allDistricts.allDistricts)      
          allDistricts.allDistricts.forEach(element => {
          districtlist.push({
            key:element._id,
            value:element.district_name
          })
         });
         setDistrict(districtlist)
                                     
        }
        else {
        console.log("In error")
           
        }
    }, [loading]);
    useEffect(() => {   
      
      var talukalist=[]  
        if (success) {         
         // console.log(allTalukas.allTalukas)      
          if(allTalukas.allTalukas?.length>0)
          {
           
            allTalukas.allTalukas.forEach(element => {
              talukalist.push({
                _id:element._id,
                taluka_name:element.taluka_name,
                district_name:element.district.district_name
            })
           });
           setTalukaList(talukalist)
          }
         
                                     
        }
        else {
        console.log("In error")
           
        }
    }, [allTalukas]);

  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);

  const ExamTalukaScreen=()=>{
    const clearForm=()=>{
     
      setTaluka("")
    
    }
   
    const onSubmit=()=>{
      if(isUpdate)
      {
        UpdateTaluka(
          {

            taluka_name:taluka,
            taluka_id:taluka_id,
            district_id:selectedDistrict

          }
        )
        if(!isUpdateLoading)
        {
          clearForm()
          setIsUpdate(false)
        }
      }
      else
      {
        CreateTaluka({
          taluka_name:taluka,
          district_id:selectedDistrict
        })
        if(!isLoading)
        {
          clearForm()
        }
      }
     
    
    
    }
    const handleKeypress = e => {
    
    if (e.keyCode === 13) {
      onSubmit();
    }
  };
  
    return(
      <div>
      <Dialog
    open={deleteDialogOpen}
    onClose={closeConfirmAlert}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Delete Record"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      Do you really want to delete this record?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeConfirmAlert}>No</Button>
      <Button onClick={deleteTaluka} autoFocus>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
     <ToastContainer />
<Grid container spacing={2} >

{ 
isSuperAdmin?
<>      
<Grid container item xs={6} direction="column" >

<Typography component="h1" variant="h6">
Taluka ऍड करा
        </Typography>
<TextBox
          // values={school_section_list}
           label={"जिल्हा "}
           id={"district"}
           name={"district"}
           autoFocus={true}             
           onChange={(e)=>{setSelectedDistrict(e.target.value)}}   
           values={districts}
           value={selectedDistrict}  
          isSelect={true}
          onKeyPress={handleKeypress}
             
          />
          <TextBox
          // values={school_section_list}
           label={"तालुका"}
           id={"taluka"}
           name={"taluka"}
           autoFocus={true}             
           onChange={(e)=>{setTaluka(e.target.value)}}   
           value={taluka}  
          isSelect={false}
          onKeyPress={handleKeypress}
             
          />
         {/* <div style={{color:"red"}}>{validateInput.school_section}</div> */}
        
          
           <Grid 
        container 
        spacing={3}                 
        >
           <Grid item xs={12} md={2} lg={3}>  
          <SubmitButton 
           caption={isUpdate? "update": "Save"} 
        
        // isLoading={isLoading}
          button_color={'green'}
          isLoading={!isUpdate? isLoading: isUpdateLoading}
          onClick={()=>onSubmit()}
          />
          </Grid>
          <Grid item xs={12} md={2} lg={3}>  

          </Grid>
          </Grid>
         
       
</Grid>
<Grid container item xs={6} direction="column" >

<DataTable columns={columns}
   Data={!loadingTaluka? talukaList:[]}/>      
</Grid>
</>
          :""              
          }
</Grid>
  </div>
    )
  }
  return (
  <AdminTemplate>{ExamTalukaScreen()}</AdminTemplate>
  )
}

export default ExamTaluka