import { api } from '../../api'
import createAgent from './createAgent'
import createDistrict from './createDistrict'
import createSchoolExam from './createSchoolExam'
import createTaluka from './createTaluka'
import deleteAgent from './deleteAgent'
import deleteDistrict from './deleteDistrict'
import deleteSchoolExam from './deleteSchoolExam'
import deleteTaluka from './deleteTaluka'
import getAgents from './getAgents'
import getAllExamForms from './getAllExamForms'
import getDistricts from './getDistricts'
import getExamSchools from './getExamSchools'
import getTaluka from './getTaluka'
import getTalukaByDistrict from './getTalukaByDistrict'
import updateAgent from './updateAgent'
import updateDistrict from './updateDistrict'
import updateSchoolExam from './updateSchoolExam'
import updateTaluka from './updateTaluka'

export const examApi = api.injectEndpoints({
    endpoints: build => ({
      
        CreateDistrict:createDistrict(build),       
        UpdateDistrict:updateDistrict(build),
        DeleteDistrict:deleteDistrict(build), 
        DeleteTaluka:deleteTaluka(build),   

        GetDistrict:getDistricts(build),
        CreateTaluka:createTaluka(build),
        GetTaluka:getTaluka(build),
        GetTalukaByDistrict:getTalukaByDistrict(build),
        UpdateTaluka:updateTaluka(build),
        CreateSchoolExam:createSchoolExam(build),
        UpdateSchoolExam:updateSchoolExam(build),
        DeleteSchoolExam:deleteSchoolExam(build),
        GetExamSchools:getExamSchools(build),
        CreateAgent:createAgent(build),
        GetAgents:getAgents(build),
        UpdateAgent:updateAgent(build),
        DeleteAgent:deleteAgent(build),
        GetAllExamForms:getAllExamForms(build),
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {
    useCreateDistrictMutation,
    useUpdateDistrictMutation,
    useGetDistrictQuery,
    useDeleteDistrictMutation,
    useCreateTalukaMutation,
    useGetTalukaQuery,
    useGetTalukaByDistrictMutation,
    useUpdateTalukaMutation,
    useDeleteTalukaMutation,
    useCreateSchoolExamMutation,
    useUpdateSchoolExamMutation,
    useDeleteSchoolExamMutation,
    useGetExamSchoolsQuery,
    useCreateAgentMutation,
    useGetAgentsQuery,
    useUpdateAgentMutation,
    useDeleteAgentMutation,
    useGetAllExamFormsQuery,
} = examApi
