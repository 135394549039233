import { Grid,DialogTitle,Typography, Box, Button, IconButton, TextField } from '@mui/material'
import React,{ useState,useEffect, useRef } from 'react'
import SubmitButton from './InputFields/SubmitButton'
import TextBox from './InputFields/TextBox'
import { useSelector,useDispatch } from 'react-redux'
import { useSchoolBySectionMutation } from '../Services/modules/school'
import { useCreateStaffMutation,useUpdateStaffMutation } from '../Services/modules/staff'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import updateStaff from '../Services/modules/staff/updateStaff'
import { clearStaffData } from '../Store/StaffData'
import { AdapterDayjs,AdapterDateFns } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
//import DateTimePicker from '@mui/lab/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
//import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import dayjs from 'dayjs';
function StaffForm({is_retire,staff_type}) {
    const dispatch = useDispatch();
    var school_id=""
var global_school_id=useSelector(state => state.userData.user_data.school_id)
if(global_school_id!==undefined)
{
 // alert(global_school_id)
school_id=global_school_id
}
   
    const userRole=useSelector(state => state.userData.user_data.role)
    useEffect(()=>{  
      clearStaffData()
     },[])
    const staff_data=useSelector(state =>state.staffData.staff_data)
console.log("staff_data",staff_data)

  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);

    const [CreateStaff, { data, isSuccess, isLoading, isError, error }] = useCreateStaffMutation();   
    const [UpdateStaff, { 
      data:updateStaffData,
       isSuccess:updateStaffIsSuccess, 
       isLoading:updateStaffIsLoading=false, 
       isError:updateStaffIsError, 
       error:updateStaffError }] = useUpdateStaffMutation();   
    
    const[file,setFile]=useState([])
    const[isupdateRecord,setUpdateRecord]=useState(false);
    const[staff_id,setStaffId]=useState(false);
    const dt = new Date().toLocaleDateString();
   // const [selectedDate, handleDateChange] = useState(dayjs("").format('DD-MM-YYYY'));
   // const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
    const [selectedDate, handleDateChange] = useState( dayjs('2014-08-18T21:11:54'));
  //const [selectedDate, handleDateChange] = useState( dayjs('18-08-2014T21:11:54'));

  
    const[Staff,setStaff]=useState({
      name:"",
      birthdate: "",
      education:"",
      position:"",
      joining_date:"", 
      retire_date:"", 
      photo:[],     
      school_id:school_id,
      school_section:"",
     
    })   
  

    const [isFormValid, setIsFormValid] = useState(false);

    
    const[SchoolBySection, {    
      data: schools= [],  
      isLoading:loading=false,
      isSuccess:successSchoolFetch=false,
      
     } ]= useSchoolBySectionMutation();
     
     const [schoolsData,setSchoolsData]=useState([])
     useEffect(() => {
      validateForm();
    }, [Staff]);
    const validateDate = (date) => {
      // Regular expression pattern to match the date format DD/MM/YYYY
      const dateFormatRegex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
      return dateFormatRegex.test(date);
    // return setIsDateValid(dateFormatRegex.test(date));
    };
    const validateForm = () => {
      const { 
        name, 
        birthdate,
        education,
        position,
        joining_date,
        retire_date,
        photo,
        school_id,
        school_section } = Staff;
     // if (name !== '' && birthdate !== '' && validateDate(birthdate) && position !== ''&& joining_date !== ''&& school_id !== ''&& school_section !== '') {
      if(!is_retire)
      {
        if(isSuperAdmin)
        {
          if (name !== '' && birthdate !== '' && validateDate(birthdate) && position !== ''&& joining_date !== ''&& school_id !== ''&& school_section !== '') {

            setIsFormValid(true);
          } 
          else 
          {
            console.log("In else")
            setIsFormValid(false);
          }
        }
        else
        {
          if (name !== '' && birthdate !== '' && validateDate(birthdate)  && position !== ''&& joining_date !== ''&& validateDate(joining_date)) {

            setIsFormValid(true);
          } 
          else 
          {
            console.log("In else")
            setIsFormValid(false);
          }
        }
        

      }
      if(is_retire)
      {
        if(isSuperAdmin)
        {
          if (name !== '' && birthdate !== '' && validateDate(birthdate) &&retire_date!==''&& validateDate(retire_date) && position !== ''&& joining_date !== ''&& validateDate(joining_date)&& school_id !== ''&& school_section !== '') {
       

            setIsFormValid(true);
          } 
          else 
          {
            console.log("In else")
            setIsFormValid(false);
          }
        }
        else
        {
          if (name !== '' && birthdate !== '' && validateDate(birthdate) &&retire_date!==''&& validateDate(retire_date) && position !== ''&& joining_date !== ''&& validateDate(joining_date)) 
          {
       

            setIsFormValid(true);
          } 
          else 
          {
            console.log("In else")
            setIsFormValid(false);
          }
        }
       
      }

     
    };   
    useEffect(() => {   
      var schoollist=[{
        key:"",
        value:""
      }]      
        if (successSchoolFetch) {         
          console.log(schools)      
          schools.data.forEach(element => {
          schoollist.push({
            key:element._id,
            value:element.name
          })
         });
         setSchoolsData(schoollist)
                                     
        }
        else {
        console.log("In error")
           
        }
    }, [loading]);
    useEffect(() => {   
     
        if (isSuccess) {         
          console.log("data",data)    
          toast.success(data.message)                   
        }
        else {
        console.log("In error")
           
        }
    }, [isLoading]);

    useEffect(() => {   
     
      if (updateStaffIsSuccess) {         
        console.log("data",updateStaffData)    
        toast.success(updateStaffData.message)  
        dispatch(clearStaffData())
        setUpdateRecord(false)                 
      }
      else {
        dispatch(clearStaffData())
        setUpdateRecord(false)   
        //toast.error("Something went wrong")
      console.log("In error")
         
      }
  }, [updateStaffIsLoading]);
 
  
    useEffect(() => {
      if(staff_data!==null)
      {
        console.log("in set staff data")
        //alert("In staff data")
        setUpdateRecord(true)
        setStaffId(staff_data._id)
        setStaff({
          ...Staff,
          name:staff_data.name,
          //school_section:staff_data.school_section,
          //school_id:staff_data.school_id,
          birthdate:staff_data.birthdate,
          education:staff_data.education,
          position:staff_data.position,
          joining_date:staff_data.joining_date, 
          retire_date:staff_data.retire_date,   
          school_id:global_school_id     
        })
        
      }
      else
      {
        setUpdateRecord(false)
        setStaff({
          ...Staff,
          name:"",        
          birthdate:"",
          education:"",
          position:"",
          joining_date:"", 
          retire_date:"",        
        })

      }
     
    },[staff_data])
   


    const teacher_position = [
      { key: "मुख्याध्यापक", value: "मुख्याध्यापक" },
      { key: "मुख्याध्यापिका", value: "मुख्याध्यापिका" },      
      { key: "प्राचार्य", value: "प्राचार्य" },
      { key: "अधिक्षक", value: "अधिक्षक" },
      { key: "सहाय्यक शिक्षक", value: "सहाय्यक शिक्षक" },
      { key: "सहाय्यक शिक्षिका", value: "सहाय्यक शिक्षिका" },
      { key: "प्राध्यापक", value: "प्राध्यापक" },
      { key: "प्राध्यापिका", value: "प्राध्यापिका" },
      { key: "उपमुख्याध्यापक", value: "उपमुख्याध्यापक" },
      { key: "उपमुख्याध्यापिका", value: "उपमुख्याध्यापिका" },      
      { key: "पर्यवेक्षक", value: "पर्यवेक्षक" }



       
    ];
    const [school_section_list,setSchoolSections] = useState([
      { key: "pp", value: "पूर्व प्राथमिक" },
      { key: "p", value: "प्राथमिक" },
      { key: "s", value: "माध्यमिक" },
      { key: "hs", value: "उच्च माध्यमिक" }
     
    ]);
    const clearForm=()=>{
      setUpdateRecord(false)
      setSchoolsData([])
      setFile([])
      setStaff({
        ...Staff,
        name:"",
        school_section:"",
        school_id:"",
        birthdate:"",
        education:"",
        position:"",
        joining_date:"", 
        retire_date:"",  
        photo:[],    
        
      })
    }
   
    const staffFormScreen=()=>{

        function onSubmit (){  
          console.log("selectedDate",selectedDate)
         
           console.log("schoolProfile",Staff)  
           console.log("File",file)
           const form = new FormData();    
         console.log("Staff.birthdate",Staff.birthdate)
if(!validateDate(Staff.birthdate))
{
  return(
    toast.error("Invalid date of birth")
  )
}
        
           form.append("name",Staff.name)
            form.append("birthdate",Staff.birthdate)
          //form.append("birthdate",birthdate)

           form.append("education",Staff.education)
           form.append("position",Staff.position)
           form.append("joining_date",Staff.joining_date)
           form.append("retire_date",Staff.retire_date)
           form.append("school_id",Staff.school_id)
           form.append('photo',file[0]);
           form.append("is_retire",is_retire)
           form.append("staff_type",staff_type)
           form.append("staff_id",staff_id)
           
           console.log("Form",form)
           if(isupdateRecord)
           {
           // var checkvalidate=  validate()
            //alert(checkvalidate)
           // if(checkvalidate)
           // {
            UpdateStaff(form)
            clearForm() 
           // }
           
           }
           else
           {
           // var checkvalidate=  validate()
            //alert(checkvalidate)
           // if(checkvalidate)
           // {
           CreateStaff(form)    
           clearForm() 
           // }
          // dispatch(clearStaffData())
           }   
         }
         const schoolSectionChange=(e)=>{
          setStaff({
            ...Staff,
            [e.target.name]: e.target.value,          
          })
       
        var payload = {
          schoolSection: e.target.value,         
      };
       SchoolBySection(payload)
        
         }
             
  function handleChange(e) {
   console.log(e)
    var value = "";
    console.log("e.target.files[0]",e.target.files)
   
    if(e.target.name=="photo")
    {
      setFile([...file, e.target.files[0]])     
      value=e.targetvalue
    }
    else
    {
    value=e.target.value
    }   
    setStaff({
      ...Staff,
      [e.target.name]: value
    });
  }
  function handleDate(e) {
   
     var value=e.target.value
     const formattedDate = formatDateString(value);
    
     setStaff({
       ...Staff,
       [e.target.name]: formattedDate
     });
     validateDate(formattedDate);
   }
   const formatDateString = (inputDate) => {
    // Remove any existing slashes from the input
    const dateWithoutSlashes = inputDate.replace(/\//g, '');

    // Restrict the input to a maximum length of 8 characters
    const maxLengthInput = dateWithoutSlashes.slice(0, 8);

    // Only allow numbers
    const validInput = maxLengthInput.replace(/\D/g, '');

    // Split the input into day, month, and year parts
    const day = validInput.slice(0, 2);
    const month = validInput.slice(2, 4);
    const year = validInput.slice(4, 8);

    // Construct the formatted date with slashes at the correct positions
    let formattedDate = '';
    if (day.length > 0) {
      formattedDate += day;
      if (day.length === 2) {
        formattedDate += '/';
      }
    }
    if (month.length > 0) {
      formattedDate += month;
      if (month.length === 2) {
        formattedDate += '/';
      }
    }
    if (year.length > 0) {
      formattedDate += year;
    }

    return formattedDate;
  };
  const handleKeyDown = (e) => {
   
    if (e.key === 'Backspace') {
      var value=e.target.value
     const formattedDate = value.replace(/\//g, '');
     // const formattedDate = Staff.birthdate.replace(/\//g, '');
      setStaff({
        ...Staff,
        [e.target.name]: formattedDate
       // birthdate: formattedDate
      });
     
    }
  };

        return (
         
    <>
     <ToastContainer />

    <Grid container spacing={2} >
    {!is_retire? <Typography component="h1" variant="h6">
    कार्यरत शिक्षकांची माहिती भरा (* Mandatory fields)
            </Typography>:
            <Typography component="h1" variant="h6">
            माजी शिक्षकांची माहिती भरा
                    </Typography>
            }
  <Grid container item xs={12} direction="column" >
  { 
    isSuperAdmin?
   <>
   
    <TextBox
               values={school_section_list}
               label={"विभाग *"}
               id={"school_section"}
               name={"school_section"}
               autoFocus={true}             
              onChange={schoolSectionChange}   
              value={Staff.school_section}  
              isSelect={true}
                 
              />
            
             </>
              :""              
              }
               { 
    isSuperAdmin?
   <>
   
    <TextBox
               values={schoolsData}
               label={"School Name *"}
               id={"school_id"}
               name={"school_id"}
              isSelect={true}
              onChange={handleChange}
              value={Staff.school_id}  
              autoFocus={false}
              />
          
              </>
              :""              
              }
  
    
    <TextBox 
            id={"name"} 
            label={"शिक्षकांचे नाव *"} 
            name={"name"} 
            autoFocus={isSuperAdmin?false:true}
            value={Staff.name}
            onChange={handleChange}          
            />
           <label style={{color:'red'}}>तारीख इंग्लिश मध्ये भरावी</label>
             <TextBox 
            id={"birthdate"} 
            label={"जन्म तारीख *"} 
            name={"birthdate"} 
            autoFocus={false}
            value={Staff.birthdate}
            onChange={handleDate}
            onKeyPress={handleKeyDown}
          
            />
          
         

              <TextBox 
            id={"education"} 
            label={"शिक्षण*"} 
            name={"education"} 
            autoFocus={false}
            value={Staff.education}
            onChange={handleChange}          
            />
            
             <TextBox
               values={teacher_position}
               label={"पद*"}
               id={"position"}
               name={"position"}
              isSelect={true}
              value={Staff.position}  
              onChange={handleChange}
              />
            
             {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
  
    
  <DatePicker 
  format="DD-MM-YYYY"  
  label="रुजू तारीख"
  name={"joining_date"}        
  value={Staff.joining_date}
  onChange={handleChange}
   />

</LocalizationProvider> */}
 <label style={{color:'red'}}>तारीख इंग्लिश मध्ये भरावी</label>
               <TextBox 
            id={"joining_date"} 
            label={"रुजू तारीख*"} 
            name={"joining_date"} 
            autoFocus={false}
            value={Staff.joining_date}
            onChange={handleDate}
            onKeyPress={handleKeyDown}
            />
          
             { 
    is_retire?
    <>
     <label>तारीख इंग्लिश मध्ये भरावी</label>
             <TextBox 
            id={"retire_date"} 
            label={"निवृत्ती तारीख*"} 
            name={"retire_date"} 
            autoFocus={false}
            value={Staff.retire_date}
            onChange={handleDate}
            onKeyPress={handleKeyDown}
            />
           
            </>
            
            :""
          
             }
           <div style={{marginTop:"5px"}}> फोटो अपलोड करा (Upto 5MB)  </div>

              <TextBox 
            id={"photo"} 
            //label={"रुजू तारीख"} 
            inputType={"file"}
            name={"photo"} 
            autoFocus={false}
            value={Staff.photo}
            onChange={handleChange}
            acceptType={"image/*"}
            />
            
               <Grid 
            container 
            spacing={3}                 
            >
               <Grid item xs={12} md={2} lg={3}>  
              <SubmitButton 
              caption={isupdateRecord? "update": "Save"} 
             isLoading={isLoading}
             disabled={!isFormValid}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
              </Grid>
              <Grid item xs={12} md={2} lg={3}>  

               <SubmitButton 
              caption={"Reset"} 
              button_color={'red'}
             
              onClick={()=>clearForm()}
              />
              </Grid>
              </Grid>
             
           
  </Grid>
  
</Grid>

</>
         
          )
    }

    return(
      staffFormScreen()
    )
 
}

export default StaffForm