import { Grid,DialogTitle,Typography, Box, FormLabel } from '@mui/material'
import React,{ useState,useEffect } from 'react'
import SubmitButton from './InputFields/SubmitButton'
import TextBox from './InputFields/TextBox'
import { useSelector,useDispatch } from 'react-redux'
import { useCreateSchoolProfileMutation } from '../Services/modules/school'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateSchoolData } from '../Store/SchoolData';

function SchoolProfileForm() {
    const dispatch = useDispatch();
    const school_id = useSelector(state => state.schoolData.school_data._id);
const school_data = useSelector(state => state.schoolData.school_data);

    const [createSchoolProfile, { data, isSuccess, isLoading, isError, error }] = useCreateSchoolProfileMutation();
    const[schoolProfile,setSchoolProfile]=useState({
        establishment_date:school_data.establishment_date,
        principal_name:school_data.principal_name,
        total_students:school_data.total_students,
        address:school_data.address,
        contact_number:school_data.contact_number,
        school_description:school_data.school_description,
        school_id:school_id,
        building_photo:""
    })
    const[file,setFile]=useState([])
    const [isFormValid, setIsFormValid] = useState(false);
    // const [validateInput, setValidateInput] = React.useState({
    //   establishment_date: "",
    //   principal_name:"",
    //   total_students:"",
    //   address:"",
    //   contact_number:"",
    //   school_description:"",

    // })
    useEffect(() => {
    
        if (isSuccess) {
            console.log("InSuccess",data.data[0]);
           // GetAllSchools()
            if(data.error==true)
            {
              toast.error(data.message)
              return 
            }
            else
            {
                dispatch(updateSchoolData(data.data[0]))
              toast.success("School profile created successfully")
             
            }
          
        }
        if (isError) {
            alert("In error")
            console.log(error);
        }
    }, [isLoading]);
    useEffect(() => {
      validateForm();
    }, [schoolProfile]);
    const validateForm = () => {
      const { establishment_date, principal_name,total_students,address,contact_number } = schoolProfile;
      if (establishment_date !== '' && principal_name !== ''&& total_students !== ''&& address !== ''&& contact_number !== '') {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    };   
    const schoolProfileFormScreen=()=>{
      
       
        function onSubmit (){ 
         
           const form = new FormData();                 
           form.append("establishment_date",schoolProfile.establishment_date)
           form.append("principal_name",schoolProfile.principal_name)
           form.append("total_students",schoolProfile.total_students)
           form.append("address",schoolProfile.address)
           form.append("contact_number",schoolProfile.contact_number)
           form.append("school_description",schoolProfile.school_description)
           form.append("school_id",schoolProfile.school_id)
           form.append('building_photo',file[0]);
   
            createSchoolProfile(form)  
         
         }
       
       
           
  function handleChange(e) {
   // alert("In handle change",e.target.name)
    var value = e.target.value;
   
    if(e.target.name=="building_photo")
    {
      setFile([ e.target.files[0]])     
      value=e.target.value
    }
   

    setSchoolProfile({
      ...schoolProfile,
      [e.target.name]: value
    });
    validateForm()
  }
        return (
            
            <Box sx={{width: "auto", mt: 1 }}>
     <ToastContainer />
            
            <TextBox 
            id={"establishment_date"} 
            label={"स्थापना तारीख*"} 
            name={"establishment_date"} 
            autoFocus={false}
            value={schoolProfile.establishment_date}
            onChange={handleChange}
            />
            
             <TextBox 
            id={"principal_name"} 
            label={"मुख्याध्यापकांचे नाव*"} 
            name={"principal_name"} 
            autoFocus={false}
            value={schoolProfile.principal_name}
            onChange={handleChange}
            />
           
             <TextBox 
            id={"total_students"} 
            label={"एकूण विद्यार्थी संख्या*"} 
            name={"total_students"} 
            autoFocus={false}
            value={schoolProfile.total_students}
            inputType={"number"}
            onChange={handleChange}
            />
            
             <TextBox 
            id={"address"} 
            label={"पत्ता*"} 
            name={"address"} 
            autoFocus={false}
            value={schoolProfile.address}
            onChange={handleChange}
            />
            
              <TextBox 
            id={"contact_number"} 
            label={"दूरध्वनी क्रमांक*"} 
            name={"contact_number"} 
            autoFocus={false}
            value={schoolProfile.contact_number}
            onChange={handleChange}
            />
            
             <TextBox 
            id={"school_description"} 
            label={"शाळेची माहिती*"} 
            name={"school_description"} 
            autoFocus={false}
            value={schoolProfile.school_description}
            onChange={handleChange}
            multiline={true}
            />
             {/* <div>{validateInput.establishment_date}</div> */}

           <div style={{marginTop:"5px"}}> शाळेच्या इमारतीचा फोटो अपलोड करा (Upto 5MB)  </div>
             <TextBox 
            id={"building_photo"} 
            
            inputType={"file"}
            name={"building_photo"} 
            autoFocus={false}
            value={schoolProfile.building_photo}
            onChange={handleChange}
            
            acceptType={"image/*"}
            />
              
            <SubmitButton 
            caption={"Save"}            
            onClick={()=>onSubmit()}
            isLoading={isLoading}
            disabled={!isFormValid}
            />
          
          </Box>
          )
    }

    return(
        schoolProfileFormScreen()
    )
 
}

export default SchoolProfileForm