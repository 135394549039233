export default build =>
    build.query({
        tagTypes: ['allStaff'],
        keepUnusedDataFor: 1,
        query(payload) {
            //console.log("payload",payload)            
            return {
                url: '/staff/getAllStaff',
                method: 'POST',
                body: payload,
            };
           
        },
       providesTags: ['allStaff']
    })
