import React,{ useState,useEffect, useRef } from 'react'
import { Grid,DialogTitle,Typography, Box, Button, IconButton, TextField } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import { useSelector,useDispatch } from 'react-redux'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import DataTable from '../Components/DataTable'
import { useCreateDistrictMutation, useDeleteDistrictMutation, useGetDistrictQuery, useUpdateDistrictMutation } from '../Services/modules/exam'
import { api } from '../Services/api'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Select, Input, Table, Icon,Space } from "antd"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

function ExamDistrict() {
  const[district_id,setDistrictId]=useState("")
  const[district,setDistrict]=useState("")
  const[isUpdate,setIsUpdate]=useState(false)
const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)

  const [CreateDistrict, { data, isSuccess, isLoading, isError, error }] = useCreateDistrictMutation();   
  const [UpdateDistrict, { data:updateResponse, isSuccess:updateSuccess, isLoading:isUpdateLoading, isError:updateError}] = useUpdateDistrictMutation();   
  const [DeleteDistrict, { data:deleteResponse, isSuccess:deleteSuccess, isLoading:isDeleteLoading, isError:deleteError}] = useDeleteDistrictMutation();   

const { 
    data: allDistricts= [],  
    isLoading:loading=true,
    isSuccess:success,
    isError:errorDistrict=false
   } = useGetDistrictQuery();

  var columns=[
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">            
           
           <a onClick={()=>editDistrict(record)} style={{color: "blue"}}><EditIcon style={{fontSize:'medium'}}/> </a>
          
            <a onClick={()=>openDeleteDistrictDialog(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>          
          </Space>
        ),
      },
    { 
        key: "district_name", 
        title: "District", 
        dataIndex: "district_name", 
        }
   ]
 
  
   const editDistrict=(record)=>{
    console.log("In edit district",record)
    setDistrict(record.district_name)
    setDistrictId(record._id)
    setIsUpdate(true)
   }
   const closeConfirmAlert=()=>{
    
    setDeleteDialogOpen(false)

   }
   const openDeleteDistrictDialog=(record)=>{   
    setDeleteDialogOpen(true)
   // DeleteDistrict({district_id:record._id})
    setDistrictId(record._id)
   }
   const deleteDistrict=()=>{   
    setDeleteDialogOpen(false)
    DeleteDistrict({district_id:district_id})
    if(deleteSuccess)
    {
      setDistrictId("")
toast.success(deleteResponse.message)
    }
   }
   useEffect(() => {   
     
    if (!loading) {         
      console.log("data",allDistricts)    
                
    }
   
}, [!loading]);
  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);

  const ExamDistrictScreen=()=>{
    const clearForm=()=>{
     
      setDistrict("")
    
    }
   
    const onSubmit=()=>{
      if(isUpdate)
      {
        UpdateDistrict(
          {
            district_name:district,
            district_id:district_id
          }
        )
        if(!isUpdateLoading)
        {
          clearForm()
          setIsUpdate(false)
        }
      }
      else
      {
        CreateDistrict({district_name:district})
        if(!isLoading)
        {
          clearForm()
        }
      }
     
    
    
    }
    const handleKeypress = e => {
    
    if (e.keyCode === 13) {
      onSubmit();
    }
  };
  
    return(
      <div>
          <Dialog
        open={deleteDialogOpen}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteDistrict} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
         <ToastContainer />
  <Grid container spacing={2} >
   
  { 
    isSuperAdmin?
   <>      
  <Grid container item xs={6} direction="column" >
 
   <Typography component="h1" variant="h6">
   जिल्हा ऍड करा
            </Typography>
    <TextBox
              // values={school_section_list}
               label={"जिल्हा "}
               id={"district"}
               name={"district"}
               autoFocus={true}             
               onChange={(e)=>{setDistrict(e.target.value)}}   
               value={district}  
              isSelect={false}
              onKeyPress={handleKeypress}
                 
              />
             {/* <div style={{color:"red"}}>{validateInput.school_section}</div> */}
            
              
               <Grid 
            container 
            spacing={3}                 
            >
               <Grid item xs={12} md={2} lg={3}>  
              <SubmitButton 
               caption={isUpdate? "update": "Save"} 
            
            // isLoading={isLoading}
              button_color={'green'}
              isLoading={!isUpdate? isLoading: isUpdateLoading}
              onClick={()=>onSubmit()}
              />
              </Grid>
              <Grid item xs={12} md={2} lg={3}>  

              </Grid>
              </Grid>
             
           
  </Grid>
  <Grid container item xs={6} direction="column" >
 
 <DataTable columns={columns}
       Data={!loading? allDistricts.allDistricts:[]}/>      
</Grid>
  </>
              :""              
              }
</Grid>
      </div>
    )
  }
  return (
    <AdminTemplate>{ExamDistrictScreen()}</AdminTemplate>
  )
}

export default ExamDistrict