export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
                url: '/school/updateSchoolInfo',
                method: 'POST',
                body: payload,
               
            };
        },
        invalidatesTags: ['allSchools'],
    })
