import { api } from '../../api'

//import getAllSchools from './getAllSchools'
import createStaff from './createStaff'
import getStaff from './getStaff'
import updateStaff from './updateStaff'
import deleteStaff from './deleteStaff'
export const staffApi = api.injectEndpoints({
    endpoints: build => ({
      
        CreateStaff:createStaff(build),
        UpdateStaff:updateStaff(build),
        DeleteStaff:deleteStaff(build),
        GetStaff:getStaff(build)
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {useCreateStaffMutation,useUpdateStaffMutation,useDeleteStaffMutation,useGetStaffQuery} = staffApi
