import { Box, Grid } from '@mui/material'
import React,{useEffect,useState,useRef} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useGetStaffQuery,useDeleteStaffMutation } from '../Services/modules/staff';
import DataTable from './DataTable';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { updateStaffData,clearStaffData } from '../Store/StaffData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextBox from './InputFields/TextBox';
import { useDeletePhotoGalleryYearMutation, useGetPhotoGalleryQuery, useSchoolBySectionMutation } from '../Services/modules/school';
import { updatePhotoGalleryData } from '../Store/PhotoGalleryData';
import { useNavigate } from 'react-router-dom';

function GalleryYearInfo({is_retire,staff_type,is_report}) {
  const navigate = useNavigate();
  const[viewStaffDetails,setViewStaffDetails]=useState({}) 
const [deleteDialogOpen,setDeleteDialogOpen]=useState(false)
const[documentId,setDocumentIdForDelete]=useState("")
const[viewDetailsDialog,setViewDetailsDialog]=useState(false);
  const[viewTransferDialog,setviewTransferDialog]=useState(false)

    const dispatch=useDispatch()
  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);
const user_role = useSelector(state => state.userData.user_data.role);

var school_id=0
var global_school_id=useSelector(state => state.userData.user_data.school_id)
if(user_role=="school_admin")
{
    school_id=global_school_id
}
const { 
    data: allGallery= [],  
    isLoading:loading=true,
    isSuccess,
    isError
   } = useGetPhotoGalleryQuery({school_id:school_id,user_role:user_role});
  console.log('allGallery',allGallery)
   const [DeletePhotoGalleryYear, { 
    data:deleteYearData,
     isSuccess:deleteYearIsSuccess, 
     isLoading:deleteYearIsLoading=false, 
     isError:deleteYearIsError, 
     error:deleteYearError }] = useDeletePhotoGalleryYearMutation();  

   var columns=[
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">            
           <a onClick={()=>addEventRedirect(record)} style={{color: "blue"}}>Add Event </a>
           
           <a onClick={()=>editStaff(record)} style={{color: "blue"}}><EditIcon style={{fontSize:'medium'}}/> </a>
         
            <a onClick={()=>deleteYearConfirmAlert(record)} style={{color: "red"}}><DeleteIcon style={{fontSize:'medium'}}/></a>          
          </Space>
        ),
      },
    { 
        key: "year", 
        title: "Year", 
        dataIndex: "year", 
        },
               
   ]
 
   const editStaff=(record)=>{
    console.log(record,"StaffRecord")
dispatch(updateStaffData(record))
   }
   const addEventRedirect=(record)=>{
    console.log(record,"StaffRecord")
dispatch(updatePhotoGalleryData(record))
navigate('/galleryevent');
   }
   const deleteYearConfirmAlert=(record)=>{
    //dispatch(clearStaffData())
    setDeleteDialogOpen(true)
    setDocumentIdForDelete(record._id)

   }
   const deleteYear=()=>{
    DeletePhotoGalleryYear({documentId:documentId})
    setDeleteDialogOpen(false)

   }
   const closeConfirmAlert=()=>{
    
    setDeleteDialogOpen(false)

   }
   const showviewDetails=(record)=>{
    setViewStaffDetails(record)
    setViewDetailsDialog(true);
   }
   const hideViewDetails=()=>{
    setViewDetailsDialog(false)
   }
   const showTransferDialog=(record)=>{
    setviewTransferDialog(true)
   }
   const hideTransferDialog=()=>{
    setviewTransferDialog(false)

   }
   useEffect(() => {   
     
    if (deleteYearIsSuccess) {         
      console.log("data",deleteYearData)    
      toast.success(deleteYearData.message)  
                   
    }
    else {
     
    console.log("In error")
       
    }
}, [deleteYearIsLoading]);
  return (
    <div>
         <ToastContainer />
         <Dialog
        open={deleteDialogOpen}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteYear} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    
       <DataTable
       columns={columns}
       Data={!loading? allGallery.photoGalleryData:[]}/>
        
    </div>
    
  )
}

export default GalleryYearInfo