import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'photoGalleryData',
    initialState: { photo_gallery_data: null },
    reducers: {
        updatePhotoGalleryData: (state, { payload }) => {
            console.log("In photoGalleryData slice",payload)
            state.photo_gallery_data = payload
        },
        clearPhotoGalleryData: (state, { }) => {
            state.photo_gallery_data = null
        },
    },
})

export const { updatePhotoGalleryData, clearPhotoGalleryData } = slice.actions

export default slice.reducer
