export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
                url: '/staff/deleteStaff',
                method: 'POST',
                body: payload,
               
            };
        },
        invalidatesTags: ['allStaff'],
    })
