import { Grid, Paper,Container } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import StaffForm from '../Components/StaffForm'
import StaffInfo from '../Components/StaffInfo'
import AdminTemplate from '../Components/Template/AdminTemplate'
import { clearStaffData } from '../Store/StaffData'


function ActiveTeachingStaff() {
  const dispatch=useDispatch()
 
  
    const activeTeachingStaffScreen=()=>{
        return(
            <Container alignItems="center" sx={{mt:5}}>
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                             <StaffForm is_retire={false} staff_type={"teaching"}/>
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                          <StaffInfo is_retire={false} staff_type={"teaching"}/>
                           
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
        )
    }
  return (
  <AdminTemplate>
    {activeTeachingStaffScreen()}
  </AdminTemplate>
  )
}

export default ActiveTeachingStaff