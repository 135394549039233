import { Grid, Paper,Container, Button } from '@mui/material'
import React, { useEffect,useState } from 'react'
import {useSelector } from 'react-redux'
import AdminTemplate from '../Components/Template/AdminTemplate'
import GalleryYearForm from '../Components/GalleryYearForm'
import GalleryYearInfo from '../Components/GalleryYearInfo'
import GalleryEventForm from '../Components/GalleryEventForm'
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import GalleryEventInfo from '../Components/GalleryEventInfo'
import SubmitButton from '../Components/InputFields/SubmitButton'

function GalleryEvent() {
  const navigate = useNavigate();
let photo_gallery_data=useSelector(state => state.photoGalleryData.photo_gallery_data)
let gallery_id=photo_gallery_data._id
let year=photo_gallery_data.year
const[loading,setLoading]=useState(true)
useEffect(() => {
  if(photo_gallery_data)
  {
    setLoading(false)
  }
  else
  {
    navigate('/galleryyear');

  }
});
const goToYear=()=>{
  navigate('/galleryyear');
}
 console.log("photo_gallery_data in event form",photo_gallery_data)
    const GalleryEventScreen=()=>{
        return(
      loading?<div>Loading...</div>: 

            <Container alignItems="center" sx={{mt:5}}>
              <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
           <Grid item xs={12} md={3} lg={3}>
           <Button
                onClick={()=>goToYear()}
                fullWidth
               // disabled={isLoading}
             
                variant="contained"
                sx={{ mt: 3, mb: 2,backgroundColor:"yellow",color:'black' }}

              >Go Back</Button>
           </Grid>
         </Grid>
             


              Create Event For Year {year}
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                            <GalleryEventForm gallery_id={gallery_id}/>
                             {/* <StaffForm is_retire={false} staff_type={"teaching"}/> */}
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                            <GalleryEventInfo gallery_id={gallery_id}/>
                          {/* <StaffInfo is_retire={false} staff_type={"teaching"}/> */}
                           
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
        )
    }
  return (
  <AdminTemplate>
    {GalleryEventScreen()}
  </AdminTemplate>
  )
}

export default GalleryEvent