import { api } from '../../api'

import getAllSchools from './getAllSchools'
import createSchool from './createSchool'
import createSchoolProfile from './createSchoolProfile'
import getSchoolById from './getSchoolById'
import getSchoolBySection from './getSchoolBySection'
import updateSchool from './updateSchool'
import deleteSchool from './deleteSchool'
import mailSchoolCredentials from './mailSchoolCredentials'
import createGalleryYear from './createGalleryYear'
import createGalleryEventName from './createGalleryEventName'
import getPhotoGallery from './getPhotoGallery'
import deletePhotoGalleryYear from './deletePhotoGalleryYear'
import deletePhotoGalleryEventName from './deletePhotoGalleryEventName'
import updateGalleryYear from './updateGalleryYear'
import updateGalleryEventName from './updateGalleryEventName'
import getPhotoGalleryById from './getPhotoGalleryById'
import createGalleryPhotos from './createGalleryPhotos'
import getGalleryPhotos from './getGalleryPhotos'
import deletePhotoGalleryPhotos from './deletePhotoGalleryPhotos'

export const getAllSchoolsApi = api.injectEndpoints({
    endpoints: build => ({
        GetAllSchools: getAllSchools(build),  
        GetSchoolById:getSchoolById(build),
        SchoolBySection:getSchoolBySection(build),
        CreateSchool:createSchool(build),
        CreateSchoolProfile:createSchoolProfile(build),
        UpdateSchool:updateSchool(build),
        DeleteSchool:deleteSchool(build),
        MailSchoolCredentials:mailSchoolCredentials(build),
        CreateGalleryYear:createGalleryYear(build),
        UpdateGalleryYear:updateGalleryYear(build),
        DeletePhotoGalleryYear:deletePhotoGalleryYear(build),        
        CreateGalleryEventName:createGalleryEventName(build),
        UpdateGalleryEventName:updateGalleryEventName(build),
        DeletePhotoGalleryEventName:deletePhotoGalleryEventName(build),
        GetPhotoGallery:getPhotoGallery(build),
        GetPhotoGalleryById:getPhotoGalleryById(build),
        CreateGalleryPhotos:createGalleryPhotos(build),
        GetGalleryPhotos:getGalleryPhotos(build),
        DeletePhotoGalleryPhotos:deletePhotoGalleryPhotos(build)
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {
    useMailSchoolCredentialsMutation, 
    useDeleteSchoolMutation, 
    useUpdateSchoolMutation,
    useSchoolBySectionMutation, 
    useGetSchoolByIdQuery, 
    useGetAllSchoolsQuery,
    useCreateSchoolMutation,
    useCreateSchoolProfileMutation,
    useCreateGalleryYearMutation,
    useUpdateGalleryYearMutation,
    useDeletePhotoGalleryYearMutation,    
    useCreateGalleryEventNameMutation,
    useUpdateGalleryEventNameMutation,
    useDeletePhotoGalleryEventName,
    useGetPhotoGalleryQuery,
    useGetPhotoGalleryByIdQuery,
    useCreateGalleryPhotosMutation,
    useGetGalleryPhotosQuery,
    useDeletePhotoGalleryPhotosMutation

 } = getAllSchoolsApi
