import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'schoolData',
    initialState: { school_data: null },
    reducers: {
        updateSchoolData: (state, { payload }) => {
            console.log("In schoodata slice",payload)
            state.school_data = payload
        },
        clearSchoolData: (state, { }) => {
            state.school_data = null
        },
    },
})

export const { updateSchoolData, clearSchoolData } = slice.actions

export default slice.reducer
